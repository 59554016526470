import { Button, Icon, useModal } from '@fleet/shared';
import {
  Dialog,
  DialogActions,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { SearchTabsContext, SearchType } from 'components/SearchTabsContext';
import { Duration, intervalToDuration, isAfter } from 'date-fns';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransParagraph } from 'i18n/trans/paragraph';
import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { resetCurrentBooking } from 'features/booking/bookingActions';

let expiryInterval: ReturnType<typeof setInterval>;

const useStyles = makeStyles(
  () => ({
    paper: {
      paddingTop: '0.5rem',
      width: '31.25rem',
    },
  }),
  { name: 'BookingExpirationTimer' }
);

export const BookingExpirationTimer = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector);
  const [durationTillExpiry, setDurationTillExpiry] = useState<Duration>();
  const { open, onOpen } = useModal();
  const { currentTab, resetTab } = useContext(SearchTabsContext);
  const isTicketSearch = useMemo(
    () => currentTab?.type === SearchType.tickets,
    [currentTab?.type]
  );

  const updateExpiryCount = useCallback(() => {
    const { confirmableUntil } = booking!;
    const [start, end] = [Date.now(), new Date(confirmableUntil)];
    const isExpired = isAfter(start, end);
    setDurationTillExpiry(
      intervalToDuration({
        start,
        end: isExpired ? start : end,
      })
    );
    if (isExpired) {
      clearInterval(expiryInterval);
      onOpen();
    }
  }, [booking, onOpen]);

  const timeTillExpiry = useMemo(() => {
    if (!durationTillExpiry) return;
    return [durationTillExpiry.minutes, durationTillExpiry.seconds]
      .map((num) => String(num).padStart(2, '0'))
      .join(':');
  }, [durationTillExpiry]);

  const redirectToSearch = useCallback(() => {
    history.replace(`/search/${isTicketSearch ? 'trip' : 'travel-pass'}`);
    resetTab();
    dispatch(resetCurrentBooking());
  }, [dispatch, history, isTicketSearch, resetTab]);

  useEffect(() => {
    if (!booking) return;
    expiryInterval = setInterval(updateExpiryCount, 1000);
    return () => clearInterval(expiryInterval);
  }, [booking, updateExpiryCount]);

  if (!timeTillExpiry) {
    return null;
  }

  return (
    <>
      <Stack direction="row" alignItems="center" spacing={1}>
        <Icon size={16} name="clock" />
        <Typography variant="body1">{timeTillExpiry}</Typography>
      </Stack>
      <Dialog
        open={open}
        classes={{
          paper: classes.paper,
        }}
      >
        <DialogTitle component={Stack} alignItems="center" spacing={3}>
          <Icon size={40} name="clock" />
          <Typography variant="title">
            <TransAlert i18nKey="bookingHasExpired" />
          </Typography>
          <Typography variant="body1">
            <TransParagraph i18nKey="bookingExpired" />
          </Typography>
        </DialogTitle>
        <DialogActions>
          <Button
            variant="contained"
            onClick={redirectToSearch}
            label={<TransButton i18nKey="backToSearch" />}
          />
        </DialogActions>
      </Dialog>
    </>
  );
};
