import {
  FormProvider,
  Modal,
  RadioGroupField,
  SelectField,
  useForm,
} from '@fleet/shared';
import { Options } from '@fleet/shared/dto/option';
import { useModal } from '@fleet/shared/hooks';
import { Button } from '@fleet/shared/mui';
import { Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { AlertCard } from 'components/AlertCard';
import { SeatProperty } from 'dto/booking';
import {
  OfferAccommodationType,
  OfferBedProperties,
  OfferGenderProperties,
  OfferPetsProperties,
  PassengerOfferSelection,
  TripOffer,
} from 'dto/trip';
import { unselectOffer, updateOfferSelection } from 'features/trip/tripActions';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import _uniq from 'lodash/uniq';
import { FC, useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useDispatch, useSelector } from 'store/utils';
import { selectSelectedOffers } from 'features/trip/tripSelector';

interface CompartmentPreferencesModalProps {
  offer: TripOffer;
}

interface SelectionForm {
  passengers: Array<{
    gender?: OfferGenderProperties;
    bed?: OfferBedProperties;
    pets?: OfferPetsProperties;
  }>;
}

const useStyles = makeStyles(
  () => ({
    paper: {
      margin: 0,
      // maxWidth: 'none',
    },
    small: {},
    hidden: {
      display: 'none',
    },
  }),
  { name: 'CompartmentPreferencesModal' }
);

const FORM_ID = 'compartment-preferences';
const NO_PROPERTY = 'NO_PROPERTY';

export const CompartmentPreferencesModal: FC<CompartmentPreferencesModalProps> =
  ({ offer }) => {
    const { id } = offer;
    const { outbound: selectedOutboundOffers } =
      useSelector(selectSelectedOffers);
    const { open, onClose } = useModal({ open: true });
    const {
      reservationLegCoverage,
      offerAccommodationType,
      reservationOfferParts,
    } = offer;
    const gridSize = useMemo(() => {
      const offerPartsNumber = reservationOfferParts.length;
      return offerPartsNumber <= 3 ? 2 : 4;
    }, [reservationOfferParts.length]);
    const maxModalWidth = useMemo(
      () => (gridSize === 2 ? 'md' : 'lg'),
      [gridSize]
    );
    const classes = useStyles();
    const dispatch = useDispatch();
    const availablePlaceProperties = useMemo(
      () =>
        _uniq(
          reservationLegCoverage
            .filter(({ placeProperties }) => placeProperties.length)
            .map(({ placeProperties }) => placeProperties)
            .flat()
        ),
      [reservationLegCoverage]
    );
    const isPropertyAvailable = useCallback(
      (property: SeatProperty) =>
        availablePlaceProperties.length &&
        availablePlaceProperties.includes(property),
      [availablePlaceProperties]
    );
    const isBedType = offerAccommodationType === OfferAccommodationType.BED;
    const journeyType = selectedOutboundOffers.trips.some(
      (offer) => offer.id === id
    )
      ? 'outbound'
      : 'inbound';

    const [genderOptions, allBedOptions, allPetsOptions] = [
      OfferGenderProperties,
      OfferBedProperties,
      OfferPetsProperties,
    ].map((props) =>
      Object.values(props).map((key) => ({
        label: renderToString(<TransLabel i18nKey={key} />),
        value: key,
      }))
    );
    const makeOptions = useCallback(
      (options: Options<string>) => [
        {
          label: renderToString(<TransLabel i18nKey="noPreference" />),
          value: NO_PROPERTY,
        },
        ...options,
      ],
      []
    );
    const [bedOptions, petsOptions] = [allBedOptions, allPetsOptions].map(
      (opts) =>
        makeOptions(
          opts.map((opt) =>
            isPropertyAvailable(opt.value) ? opt : { ...opt, disabled: true }
          ) as Options<string>
        )
    );
    const availableBedOptions = bedOptions.filter(({ disabled }) => !disabled);
    const availablePetsOptions = petsOptions.filter(
      ({ disabled }) => !disabled
    );
    const propertiesUnavailable = useMemo(
      () =>
        [
          availablePetsOptions,
          ...(isBedType ? [availableBedOptions, genderOptions] : []),
        ].every((opts) => opts.length === 1),
      [availableBedOptions, availablePetsOptions, genderOptions, isBedType]
    );
    const handleOnClose = useCallback(() => {
      onClose();
      dispatch(
        propertiesUnavailable
          ? updateOfferSelection({
              offerId: offer.id,
              selections: [],
              journeyType,
            })
          : unselectOffer({ offerId: offer.id, journeyType })
      );
    }, [dispatch, offer.id, onClose, propertiesUnavailable, journeyType]);
    const onSubmit = useCallback(
      ({ passengers }: SelectionForm) => {
        const {
          id: offerId,
          offerAccommodationType,
          reservationOfferParts,
        } = offer;
        const selections = propertiesUnavailable
          ? []
          : reservationOfferParts.map(
              ({ reservationId, passengerRefs, availablePlaces }, idx) => {
                const {
                  legId,
                  tripId,
                  accommodationType,
                  accommodationSubType,
                } = availablePlaces[0];
                return {
                  passengerIds: passengerRefs,
                  tripLegCoverage: {
                    tripId,
                    legId,
                  },
                  accommodationType,
                  accommodationSubType,
                  reservationId,
                  placeProperties: Object.values(passengers[idx]).filter(
                    (property) => {
                      const propsToFilter = [
                        NO_PROPERTY,
                        ...(offerAccommodationType ===
                        OfferAccommodationType.BED
                          ? []
                          : Object.values(OfferGenderProperties)),
                      ];
                      return property && !propsToFilter.includes(property);
                    }
                  ),
                };
              }
            );
        dispatch(
          updateOfferSelection({
            offerId,
            selections: selections as PassengerOfferSelection[],
            journeyType,
          })
        );
      },
      [dispatch, offer, propertiesUnavailable, journeyType]
    );

    const initialValues = useMemo(
      () => ({
        passengers: offer.reservationOfferParts.map(() => ({
          gender: OfferGenderProperties.MIXED,
          bed: NO_PROPERTY as OfferBedProperties,
          pets: !!availablePetsOptions.find(
            ({ value }) => value === OfferPetsProperties.WITHOUT_ANIMALS
          )
            ? OfferPetsProperties.WITHOUT_ANIMALS
            : (NO_PROPERTY as OfferPetsProperties),
        })),
      }),
      [offer, availablePetsOptions]
    );

    const { form, handleSubmit } = useForm<SelectionForm>({
      onSubmit,
      initialValues,
    });
    return (
      <Modal
        classes={{
          paper: classes.paper,
        }}
        maxWidth={maxModalWidth}
        title={<TransTitle i18nKey="compartmentPreferences" />}
        open={open}
        onClose={handleOnClose}
        showCloseControl={false}
        actionButton={
          <Button variant="contained" form={FORM_ID} type="submit">
            <TransButton i18nKey="confirmSelection" />
          </Button>
        }
      >
        <FormProvider form={form}>
          <Grid
            component="form"
            id={FORM_ID}
            container
            columns={gridSize}
            spacing={4}
            rowSpacing={2}
            onSubmit={handleSubmit}
          >
            {propertiesUnavailable && (
              <Grid item xs={1}>
                <AlertCard
                  message={<TransAlert i18nKey="noPropsAvailable" />}
                />
              </Grid>
            )}
            {reservationOfferParts.map(({ passengerRefs }, idx, parts) => {
              return (
                <Grid item key={passengerRefs[0]} xs={1}>
                  <Stack spacing={1.5}>
                    <Stack direction="row" spacing={0.5}>
                      <Typography variant="subtitle">
                        {`${idx + 1} / ${parts.length}`}
                      </Typography>
                      <Typography variant="subtitle" color="text.secondary">
                        <TransLabel i18nKey={offerAccommodationType} />
                      </Typography>
                    </Stack>
                    {isBedType && (
                      <>
                        <SelectField
                          label={<TransField i18nKey="compartmentGender" />}
                          name={`passengers[${idx}].gender`}
                          options={genderOptions}
                          required={!!genderOptions.length}
                        />
                        <SelectField
                          label={<TransField i18nKey="bedPreference" />}
                          name={`passengers[${idx}].bed`}
                          {...(availableBedOptions.length === 1 && {
                            defaultValue: availableBedOptions[0].value,
                          })}
                          options={availableBedOptions}
                          disabled={availableBedOptions.length === 1}
                          required={availableBedOptions.length > 1}
                        />
                      </>
                    )}
                    <RadioGroupField
                      name={`passengers[${idx}].pets`}
                      label={<TransField i18nKey="bedPreference" />}
                      options={petsOptions}
                      {...(availablePetsOptions.length === 1 && {
                        defaultValue: availablePetsOptions[0].value,
                      })}
                      required={availablePetsOptions.length > 1}
                      inline
                    />
                  </Stack>
                </Grid>
              );
            })}
          </Grid>
        </FormProvider>
      </Modal>
    );
  };
