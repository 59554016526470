import { Icon } from '@fleet/shared';
import { Tooltip } from '@fleet/shared/mui';
import { Box, Divider, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { BookingExpirationTimer } from 'components/BookingExpirationTimer';
import { BookingTotalFee } from 'components/BookingTotalFee';
import { SearchTabsContext, SearchType } from 'components/SearchTabsContext';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useContext } from 'react';
import { JourneyOverview } from 'routes/tickets/checkout/JourneyOverview';
import { useSelector } from 'store/utils';
import { formatCurrency } from 'utils/common';

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: 'fixed',
      bottom: 0,
      left: 0,
      right: 0,
      zIndex: 1,
      background: 'white',
      boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.1)',
    },
    dark: {
      color: 'white',
      background: theme.palette.secondary.main,
    },
    content: {
      minWidth: 976,
      maxWidth: 1416,
      padding: '1rem 1.5rem',
      margin: '0 auto',
    },
    tooltip: {
      padding: 0,
      maxWidth: 'none',
      top: '-1rem',
    },
    tooltipDivider: {
      alignSelf: 'center',
      width: '100%',
    },
    total: {
      cursor: 'pointer',
    },
  }),
  { name: 'CartTotal' }
);

interface CartTotalProps {
  isDark?: boolean;
  offersTotal?: { amount: number; currency: string };
}

export const CartTotal: FC<CartTotalProps> = ({
  isDark,
  offersTotal,
  children,
}) => {
  const { currentTab } = useContext(SearchTabsContext);
  const booking = useSelector(currentBookingSelector);
  const total = offersTotal ?? booking?.provisionalPrice;
  const classes = useStyles();

  return (
    <Box className={classNames(classes.root, { [classes.dark]: isDark })}>
      <Stack
        direction="row"
        alignItems="center"
        spacing={2}
        className={classes.content}
      >
        {booking && <BookingExpirationTimer />}
        <Stack direction="row" justifyContent="space-between" flex={1}>
          <Tooltip
            theme="light"
            placement="top-start"
            content={
              currentTab?.type === SearchType.tickets ? (
                <Stack
                  spacing={0}
                  px={3}
                  divider={<Divider className={classes.tooltipDivider} />}
                >
                  {booking?.bookedTrips.map((trip) => (
                    <JourneyOverview trip={trip} key={trip.id} />
                  ))}
                  <BookingTotalFee />
                </Stack>
              ) : null
            }
            className={classes.tooltip}
          >
            <Stack
              direction="row"
              alignItems="center"
              spacing={1}
              className={classes.total}
            >
              <Icon size={24} name="cart" />
              <Typography variant="h2">
                <TransSubtitle
                  i18nKey="cartTotal"
                  values={{
                    total: total && formatCurrency(total),
                  }}
                />
              </Typography>
            </Stack>
          </Tooltip>
          <Stack direction="row">{children}</Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
