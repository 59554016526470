import { combineReducers } from '@reduxjs/toolkit';
import { concessionReducer } from 'features/concession/concessionReducer';
import { tripReducer } from 'features/trip/tripReducer';
import { loadingReducer } from 'features/loading/loadingReducer';
import { classificationReducer } from 'features/classification/classificationReducer';
import { bookingReducer } from 'features/booking/bookingReducer';
import { userReducer } from 'features/user/userReducer';

export default combineReducers({
  trip: tripReducer,
  booking: bookingReducer,
  loading: loadingReducer,
  classification: classificationReducer,
  user: userReducer,
  concession: concessionReducer,
});
