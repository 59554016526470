import {
  Button,
  CounterField,
  currentDateTimeFormat,
  DateField,
  formatDate,
  FormProvider,
  Icon,
  Modal,
  ReadOnlyField,
  Table,
  TableColumns,
  useForm,
  useModal,
} from '@fleet/shared';
import { Grid, IconButton, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { TravelPassConsumption } from 'dto/booking';
import {
  updateTravelPass,
  UpdateTravelPassPayload,
} from 'features/booking/bookingActions';
import {
  bookingAdmissionsSelector,
  travelAccountSelector,
} from 'features/booking/bookingSelectors';
import {
  carriersSelector,
  stopsSelector,
} from 'features/classification/classificationSelectors';
import { travelPassLoading } from 'features/loading/loadingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransTableHead } from 'i18n/trans/table';
import { TransTitle } from 'i18n/trans/title';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { useTable } from 'react-table';
import { useDispatch, useSelector } from 'store/utils';
import { validate } from 'uuid';

interface PassengerTravelPassProps {}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      '& label': {
        color: theme.palette.text.secondary,
      },
    },
    viewBtn: {
      padding: 0,
      textDecoration: 'underline',
    },
  }),
  {
    name: 'PassengerTravelPass',
  }
);

export const PassengerTravelPass: FC<PassengerTravelPassProps> = () => {
  const editFormId = 'travelPassEditForm';
  const stops = useSelector(stopsSelector);
  const loading = useSelector(travelPassLoading);
  const travelAccount = useSelector(travelAccountSelector);
  const [travelPassAdmission] = useSelector(bookingAdmissionsSelector);
  const travelPass = useMemo(() => {
    const { products, fulfillments } = travelPassAdmission;

    return {
      controlNumber: fulfillments[0].controlNumber,
      conditions: products[0].conditions,
      code: products[0].code,
      type: products[0].type,
    };
  }, [travelPassAdmission]);
  const dispatch = useDispatch();
  const isMultiRide = travelPass.type === 'ADMISSION_MULTI_RIDE';
  const carriers = useSelector(carriersSelector);
  const carrierName = useMemo(() => {
    const [fulfillment] = travelPassAdmission.fulfillments;
    const carrierCode = fulfillment.issuer;
    const carrier = carriers.find(({ id }) => id === carrierCode);
    return carrier?.name;
  }, [carriers, travelPassAdmission.fulfillments]);
  const tableData = useMemo(
    () => travelAccount?.consumptions ?? [],
    [travelAccount?.consumptions]
  );
  const nrOfUsages = useMemo(
    () =>
      isMultiRide && travelAccount?.balance
        ? (['remaining', 'total'] as const)
            .map((field) => travelAccount.balance[field])
            .join(' / ')
        : '',
    [isMultiRide, travelAccount]
  );

  const validityInfo = useMemo(() => {
    if (!travelAccount) return '';
    return (['validFrom', 'validUntil'] as const)
      .map((field) => formatDate(travelAccount[field]))
      .join(' to ');
  }, [travelAccount]);

  const classes = useStyles();
  const { open, onOpen, onClose } = useModal();
  const {
    open: editOpen,
    onOpen: onEditOpen,
    onClose: onEditClose,
  } = useModal();

  const handleTravelPassEdit = useCallback(
    async (payload: UpdateTravelPassPayload) => {
      await dispatch(
        updateTravelPass({ ...payload, number: travelAccount!.number })
      );
      onEditClose();
    },
    [dispatch, onEditClose, travelAccount]
  );

  const { form, handleSubmit } = useForm({
    onSubmit: handleTravelPassEdit,
  });
  const columns = useMemo<TableColumns<TravelPassConsumption>>(
    () => [
      {
        accessor: 'consumedOn',
        Header: <TransTableHead i18nKey="usedOn" />,
        Cell: ({ value }) => formatDate(value, currentDateTimeFormat),
        width: '8.5rem',
      },
      {
        id: 'summary',
        accessor: ({ tripSummary: { origin, destination } }) =>
          [origin, destination]
            .map(
              ({ stopPlaceRef }) =>
                stops.find(({ id }) => stopPlaceRef === id)!.name
            )
            .join(' - '),
        Header: <TransTableHead i18nKey="journey" />,
        width: 'auto',
      },
      {
        id: 'startTime',
        accessor: ({ tripSummary: { startTime } }) =>
          formatDate(startTime, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="departureTime" />,
        width: '8rem',
      },
      {
        id: 'endTime',
        accessor: ({ tripSummary: { endTime } }) =>
          formatDate(endTime, currentDateTimeFormat),
        Header: <TransTableHead i18nKey="arrivalTime" />,
        width: '8rem',
      },
      {
        id: 'bookingId',
        accessor: ({ bookingId }) => (
          <Link
            to={
              validate(bookingId)
                ? `/booking/${bookingId}`
                : `/bookings?reference=${bookingId}`
            }
          >
            {bookingId}
          </Link>
        ),
        Header: <TransTableHead i18nKey="bookingNr" />,
      },
    ],
    [stops]
  );
  const table = useTable({
    data: tableData,
    columns,
  });

  useEffect(() => {
    travelAccount &&
      form.reset({
        validUntil: travelAccount.validUntil,
        numberOfUsages: travelAccount?.balance?.remaining,
      });
  }, [form, travelAccount]);

  return (
    <>
      <Grid
        container
        columns={19}
        spacing={2}
        rowSpacing={1}
        classes={{
          root: classes.root,
        }}
      >
        <Grid item xs={3}>
          <ReadOnlyField
            value={travelPass.conditions
              .map(({ description }) => description)
              .join('\n')}
            label={<TransField i18nKey="conditions" />}
          />
        </Grid>
        <Grid item xs={16} />
        <Grid item xs={3}>
          <ReadOnlyField
            value={travelPass.controlNumber}
            label={<TransField i18nKey="travelPassNr" />}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyField
            value={travelPass.code}
            label={<TransField i18nKey="travelPassCode" />}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyField
            value={carrierName}
            label={<TransField i18nKey="carrier" />}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyField
            value={!travelPass.type || <TransLabel i18nKey={travelPass.type} />}
            label={<TransField i18nKey="type" />}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyField
            value={
              <Button
                variant="text"
                className={classes.viewBtn}
                onClick={onOpen}
                label={
                  <Typography variant="body2">
                    <TransButton i18nKey="viewUsage" />
                  </Typography>
                }
              />
            }
            label={<TransField i18nKey="numberOfRemainingUsages" />}
          />
        </Grid>
        <Grid item xs={3}>
          <ReadOnlyField
            value={validityInfo}
            label={<TransField i18nKey="valid" />}
          />
        </Grid>
        <Grid item xs={1}>
          <ReadOnlyField
            value={
              <IconButton onClick={onEditOpen}>
                <Icon name="edit" />
              </IconButton>
            }
            label={<>&nbsp;</>}
          />
        </Grid>
      </Grid>
      <Modal
        title={<TransTitle i18nKey="travelPassUsage" />}
        open={open}
        onClose={onClose}
        actionButton={<></>}
      >
        <Stack>
          {isMultiRide && (
            <ReadOnlyField
              value={nrOfUsages}
              label={<TransField i18nKey="numberOfRemainingUsages" />}
            />
          )}
          <Table table={table} />
        </Stack>
      </Modal>
      <Modal
        title={<TransTitle i18nKey="editTravelPass" />}
        open={editOpen}
        onClose={onEditClose}
        actionButton={
          <Button
            startIcon={<Icon name="check" />}
            variant="contained"
            loading={loading}
            form={editFormId}
            type="submit"
            label={<TransButton i18nKey="confirm" />}
          />
        }
      >
        <FormProvider form={form}>
          <Stack
            component="form"
            id={editFormId}
            onSubmit={handleSubmit}
            spacing={2}
          >
            <DateField
              name="validUntil"
              label={<TransField i18nKey="validTo" />}
            />
            {isMultiRide && (
              <CounterField
                name="numberOfUsages"
                label={<TransField i18nKey="usagesLeft" />}
              />
            )}
          </Stack>
        </FormProvider>
      </Modal>
    </>
  );
};
