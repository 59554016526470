import { DateField, formatDate, getStartOfDate } from '@fleet/shared';
import { FormField, FormProvider, useForm } from '@fleet/shared/form';
import { currentLocaleConfiguration } from '@fleet/shared/i18n';
import { Button, Icon } from '@fleet/shared/mui';
import { SelectOption } from '@fleet/shared/mui/Select';
import {
  Box,
  ButtonBase,
  Divider,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { PromoCodes } from 'components/searchBar/PromoCodes';
import { SearchFilters } from 'components/searchBar/SearchFilters';
import { SearchStopsSelect } from 'components/searchBar/SearchStopsSelect';
import { SearchTextField } from 'components/searchBar/SearchTextField';
import { SearchTabsContext, SearchType } from 'components/SearchTabsContext';
import { isBefore } from 'date-fns';
import startOfDay from 'date-fns/startOfDay';
import {
  EnhancedTripSearchParams,
  SearchBarFormValues,
  TravelPassSearchParams,
  TripSearchParams,
} from 'dto/trip';
import { stopsSelector } from 'features/classification/classificationSelectors';
import { searchLoadingSelector } from 'features/loading/loadingSelectors';
import { searchTravelPasses, searchTrips } from 'features/trip/tripActions';
import { RecentTrip } from 'features/user/userActions';
import { selectDefaultCurrency } from 'features/user/userSelector';
import {
  RECENT_STOPS_LS_KEY,
  RECENT_TRIPS_LS_KEY,
  useLocalStorage,
} from 'hooks/useLocalStorage';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import _take from 'lodash/take';
import _uniqBy from 'lodash/uniqBy';
import {
  FC,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useAlert } from 'react-alert';
import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'store/utils';
import { IS_DS_AT } from 'utils/flags';
import { v4 } from 'uuid';
import { SearchPassengers } from './SearchPassengers';

const useStyles = makeStyles(
  (theme) => ({
    searchBarWrap: {
      boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
    },
    searchToggleControls: {
      width: '4.75rem',
      height: 'auto',
      borderRight: `1px solid ${theme.palette.divider}`,
      '& .MuiToggleButton-root': {
        flex: 1,
        textTransform: 'none',
        background: theme.palette.background.default,
        border: 'none',
        borderRadius: 0,
        color: '#757B86',
        '&.Mui-selected': {
          color: theme.palette.primary.main,
          background: theme.palette.common.white,
        },
        '& > p': {
          fontWeight: 600,
        },
      },
    },
    searchBar: {
      flex: 1,
      height: 80,
      background: theme.palette.common.white,
      '&:not($ticketSearch)': {
        '& .MuiAutocomplete-root': {
          flex: 1,
        },
        '& > div:not(.MuiAutocomplete-root)': {
          width: '20%',
        },
      },
    },
    ticketSearch: {
      '& > div': {
        flex: 1,
      },
    },
    switchBtnWrap: {
      position: 'relative',
      zIndex: 1,
      '& + hr': {
        display: 'none',
      },
    },
    switchBtn: {
      padding: 4,
      position: 'absolute',
      top: '50%',
      border: `1px solid ${theme.palette.divider}`,
      borderRadius: '50%',
      background: theme.palette.common.white,
      transform: 'translate3d(-50%, -50%, 0)',
      cursor: 'pointer',
      '&:hover': {
        boxShadow: [
          alpha(theme.palette.action.hover, 0.2),
          theme.palette.common.white,
        ]
          .map((color) => `inset 0 0 0 2rem ${color}`)
          .join(','),
      },
    },
    submitBtn: {
      width: '15%',
      padding: 0,
      borderRadius: 0,
    },
  }),
  { name: 'SearchBar' }
);

interface SearchBarProps {
  path: string;
}

export const SearchBar: FC<SearchBarProps> = ({ children, path }) => {
  const alert = useAlert();
  const history = useHistory();
  const classes = useStyles();
  const dispatch = useDispatch();
  const minDate = useMemo(() => getStartOfDate(new Date()), []);
  const location = useLocation();
  const { activeTabIdx, currentTab, updateTab } = useContext(SearchTabsContext);
  const isTicketSearch = useMemo(
    () => currentTab?.type === SearchType.tickets,
    [currentTab?.type]
  );
  const stops = useSelector(stopsSelector);
  const defaultCurrency = useSelector(selectDefaultCurrency);
  const searchLoading = useSelector(searchLoadingSelector);
  const { initialValue: lsRecentStops, setData: setLsRecentStops } =
    useLocalStorage<Array<SelectOption>>({
      key: RECENT_STOPS_LS_KEY,
      getDefaultValue: (value) => value?.filter(({ label }) => label) || [],
    });
  const { initialValue: lsRecentTrips, setData: setLsRecentTrips } =
    useLocalStorage<Array<RecentTrip>>({
      key: RECENT_TRIPS_LS_KEY,
      getDefaultValue: (value) => value || [],
    });
  const [recentStops, setRecentStops] = useState(lsRecentStops);
  const updateRecentStops = useCallback(
    ([originStop, destinationStop]: Array<{ code: string }>) => {
      if (!stops.length) return;
      const updatedRecentStops = _take(
        _uniqBy(
          [
            ...[originStop.code, destinationStop.code].map((code) => ({
              label: stops.find(({ id }) => id === code)?.name,
              value: code,
            })),
            ...recentStops,
          ],
          'value'
        ),
        10
      );
      setLsRecentStops(updatedRecentStops);
      setRecentStops(updatedRecentStops);
    },
    [recentStops, setLsRecentStops, stops]
  );
  const updateRecentTrips = useCallback(
    ([originStop, destinationStop]: Array<{ code: string }>) => {
      if (!stops.length) return;
      const [origin, destination] = [originStop, destinationStop].map(
        ({ code }) => {
          const currentStop = stops.find(({ id }) => id === code);
          return (
            currentStop && {
              name: currentStop.name,
              code: currentStop.id,
            }
          );
        }
      );

      origin &&
        destination &&
        setLsRecentTrips(
          _uniqBy(
            [{ origin, destination }, ...lsRecentTrips],
            ({ origin, destination }) => origin.code + destination.code
          )
        );
    },
    [lsRecentTrips, setLsRecentTrips, stops]
  );

  const onSubmit = useCallback(
    async (values: TripSearchParams | TravelPassSearchParams) => {
      const {
        passengerSpecifications,
        arrivalTime,
        departureTime,
        via,
        searchCriteria,
      } = values as TripSearchParams;
      const { ptModesFilter, carriersFilter, transfersFilter } =
        searchCriteria ?? {};
      const isDepartureDateInPast = (departureTime: string) =>
        isBefore(startOfDay(new Date(departureTime)), startOfDay(new Date()));
      const commonPayload = {
        ...values,
        passengerSpecifications: passengerSpecifications.map(
          // eslint-disable-next-line @typescript-eslint/no-unused-vars
          ({ concession: _, ...specification }) => specification
        ),
        currency: defaultCurrency!,
      };
      const preparedOutboundPayload:
        | EnhancedTripSearchParams
        | TravelPassSearchParams = isTicketSearch
        ? {
            ...commonPayload,
            departureTime,
            ...(arrivalTime && { arrivalTime }),
            ...(via && { via }),
            ...(searchCriteria && {
              searchCriteria: {
                ...(ptModesFilter?.ptModes.length && { ptModesFilter }),
                ...(carriersFilter?.carriers.length && { carriersFilter }),
                transfersFilter,
              },
            }),
          }
        : {
            ...commonPayload,
            validityStartTime: startOfDay(new Date()).toISOString(),
          };

      updateRecentTrips([values.originStop, values.destinationStop]);
      updateRecentStops([values.originStop, values.destinationStop]);

      if (
        'departureTime' in values &&
        isDepartureDateInPast(values.departureTime)
      ) {
        return alert.error(<TransAlert i18nKey="departureDateIsInPast" />);
      }
      history.replace(`${path}/${isTicketSearch ? 'trip' : 'travel-pass'}`);

      if (isTicketSearch) {
        if (arrivalTime) {
          await Promise.all([
            dispatch(
              searchTrips({
                ...preparedOutboundPayload,
                returnSearchParameters: {
                  outwardTripDate: undefined,
                  inwardReturnDate: arrivalTime,
                },
                journeyType: 'outbound',
              } as EnhancedTripSearchParams)
            ),
            dispatch(
              searchTrips({
                ...preparedOutboundPayload,
                departureTime: arrivalTime,
                destinationStop: preparedOutboundPayload.originStop,
                originStop: preparedOutboundPayload.destinationStop,
                returnSearchParameters: {
                  outwardTripDate: departureTime,
                  inwardReturnDate: undefined,
                },
                journeyType: 'inbound',
              } as EnhancedTripSearchParams)
            ),
          ]);
        } else {
          await dispatch(
            searchTrips({
              ...preparedOutboundPayload,
              journeyType: 'outbound',
            } as EnhancedTripSearchParams)
          );
        }
      } else {
        await dispatch(
          searchTravelPasses(preparedOutboundPayload as TravelPassSearchParams)
        );
      }
    },
    [
      updateRecentTrips,
      updateRecentStops,
      isTicketSearch,
      history,
      path,
      alert,
      dispatch,
      defaultCurrency,
    ]
  );

  const hideSearchBar = useMemo(
    () =>
      ['checkout', 'success'].some((path) => location.pathname.includes(path)),
    [location]
  );
  const initialValues = useMemo<Partial<SearchBarFormValues>>(
    () => ({
      cardState: {
        cardToggleStateMap: currentTab?.cardState?.cardToggleStateMap || {},
      },
      passengerSpecifications: [
        {
          type: 'PERSON',
          externalReference: v4(),
        },
      ],
      ...(isTicketSearch && {
        departureTime: formatDate(new Date(), "yyyy-MM-dd'T'HH:mm:ss"),
      }),
      promotionCodes: [],
      corporateCodes: [],
    }),
    [isTicketSearch, currentTab]
  );

  const {
    form,
    handleSubmit,
    values: searchParams,
    hasValidationErrors,
  } = useForm<SearchBarFormValues>({
    subscription: { hasValidationErrors: true, values: true },
    initialValues,
    onSubmit,
  });

  const updateCurrentTabParams = useCallback(() => {
    updateTab(
      {
        params: form.getState().values,
      },
      activeTabIdx
    );
  }, [form, updateTab, activeTabIdx]);

  useEffect(() => {
    form.restart({ ...initialValues, ...currentTab?.params });
  }, [form, initialValues, currentTab]);

  const switchDestinations = useCallback(() => {
    const { originStop, destinationStop, ...rest } = searchParams;
    form.reset({
      ...rest,
      originStop: destinationStop,
      destinationStop: originStop,
    });
  }, [form, searchParams]);

  const toggleSearchType = useCallback(
    (_, value: SearchType) => {
      if (!value) return;
      updateTab(
        {
          type: value,
        },
        activeTabIdx
      );
      if (value === 'tickets') {
        form.change('departureTime', undefined);
      } else {
        form.change('departureTime', new Date().toISOString());
      }
      value === 'travelPass' && form.reset();
      history.replace(path);
    },
    [activeTabIdx, form, history, path, updateTab]
  );

  if (!currentTab) return null;

  return (
    <FormProvider form={form}>
      <Stack
        component="form"
        name="searchBar"
        sx={{
          mb: 3,
          ...(hideSearchBar && {
            display: 'none',
          }),
        }}
        onSubmit={handleSubmit}
      >
        <Stack direction="row" className={classes.searchBarWrap}>
          <ToggleButtonGroup
            className={classes.searchToggleControls}
            orientation="vertical"
            value={currentTab.type}
            exclusive
            onChange={toggleSearchType}
          >
            {Object.values(SearchType).map((val) => (
              <ToggleButton
                key={val}
                value={val}
                size="small"
                disabled={currentTab.addingJourneysBeforeCheckout}
              >
                <Typography variant="body2">
                  <TransField i18nKey={val} />
                </Typography>
              </ToggleButton>
            ))}
          </ToggleButtonGroup>
          <Stack
            className={classNames(classes.searchBar, {
              [classes.ticketSearch]: currentTab?.type === 'tickets',
            })}
            direction="row"
            divider={<Divider orientation="vertical" flexItem />}
          >
            <FormField<string>
              required
              name="originStop.code"
              render={({ input }) => (
                <SearchStopsSelect
                  label={<TransField i18nKey="from" />}
                  onChange={input.onChange}
                  value={input.value}
                  recentStops={recentStops}
                />
              )}
            />
            <Box className={classes.switchBtnWrap} component="span">
              <ButtonBase
                className={classes.switchBtn}
                onClick={switchDestinations}
              >
                <Icon name="switch" size={24} />
              </ButtonBase>
            </Box>
            <FormField<string>
              required
              name="destinationStop.code"
              render={({ input }) => (
                <SearchStopsSelect
                  label={<TransField i18nKey="to" />}
                  onChange={input.onChange}
                  value={input.value}
                  recentStops={recentStops}
                />
              )}
            />
            {isTicketSearch &&
              (['departureTime', 'arrivalTime'] as const).map((field) => (
                <DateField
                  key={field}
                  name={field}
                  dateFormat={`EEE, dd/MM ${currentLocaleConfiguration.timeFormat}`}
                  shouldCloseOnSelect={false}
                  showWeekNumbers
                  showTimeInput
                  {...(field === 'departureTime'
                    ? { minDate: minDate, required: true }
                    : {
                        minDate: new Date(searchParams.departureTime),
                      })}
                  isClearable={false}
                  customInput={
                    <SearchTextField label={<TransField i18nKey={field} />} />
                  }
                />
              ))}
            <SearchPassengers name="passengerSpecifications" />
            {isTicketSearch && IS_DS_AT && <SearchFilters />}
            <Button
              size="large"
              type="submit"
              className={classes.submitBtn}
              disabled={hasValidationErrors}
              loading={searchLoading}
              label={
                <Typography variant="h2">
                  <TransButton i18nKey="search" />
                </Typography>
              }
              onClick={updateCurrentTabParams}
            />
          </Stack>
        </Stack>
        <PromoCodes />
      </Stack>
      {children}
    </FormProvider>
  );
};
