import { createReducer } from '@reduxjs/toolkit';
import { Warning, Warnings } from 'dto/booking';
import {
  Journey,
  JourneyLink,
  PassengerOfferSelection,
  PaymentStatus,
  TravelPass,
  TripLeg,
  TripOffer,
} from 'dto/trip';
import {
  clearOfferSelection,
  clearPaymentStatus,
  getPaymentStatus,
  payByLink,
  resetSearch,
  resetTripOffers,
  searchExchangeOffers,
  searchExchangeUpgradeOffers,
  searchTravelPasses,
  searchTrips,
  selectTravelPassOffer,
  selectTripOffer,
  setPdfDownload,
  showTripsResultPage,
  showTripStops,
  unselectOffer,
  updateOfferSelection,
} from 'features/trip/tripActions';
import _omit from 'lodash/omit';

export interface SelectedOffers {
  reference?: string;
  trips: Array<TripOffer>;
  tripsSelectionMap: Record<string, Array<PassengerOfferSelection>>;
}

interface TripState {
  outbound: Array<Journey>;
  inbound: Array<Journey>;
  links: Array<JourneyLink>;
  warnings: Array<Warning>;
  travelPasses: Array<TravelPass>;
  showStopsFor?: TripLeg;
  downloadPdfTickets: boolean;
  paymentStatus?: PaymentStatus;
  selectedOffers: {
    outbound: SelectedOffers;
    inbound: SelectedOffers;
    travelPass?: TravelPass;
  };
  nextAvailableDepartureDate?: string;
}

const initialState: TripState = {
  outbound: [],
  inbound: [],
  links: [],
  warnings: [],
  travelPasses: [],
  downloadPdfTickets: true,
  selectedOffers: {
    outbound: {
      trips: [],
      tripsSelectionMap: {},
    },
    inbound: {
      trips: [],
      tripsSelectionMap: {},
    },
  },
};

export const tripReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(searchTravelPasses.pending, (state) => {
      state.travelPasses = [];
    })
    .addCase(searchTravelPasses.fulfilled, (state, action) => {
      state.travelPasses = action.payload.nonTripOffers;
    })
    .addCase(showTripStops, (state, action) => {
      state.showStopsFor = action.payload;
    })
    .addCase(setPdfDownload, (state, action) => {
      state.downloadPdfTickets = action.payload;
    })
    .addCase(resetTripOffers, (state, action) => {
      const { journeyType, reference } = action.payload;
      state.selectedOffers[journeyType] = {
        ...initialState.selectedOffers[journeyType],
        reference: reference,
      };
    })
    .addCase(selectTripOffer, (state, action) => {
      const { offers, reference, journeyType } = action.payload;
      state.selectedOffers[journeyType].reference = reference;
      state.selectedOffers[journeyType].trips = offers;
    })
    .addCase(selectTravelPassOffer, (state, action) => {
      state.selectedOffers.travelPass = action.payload;
    })
    .addCase(updateOfferSelection, (state, action) => {
      const { offerId, selections, journeyType } = action.payload;
      const { tripsSelectionMap } = state.selectedOffers[journeyType];
      const selectedOfferIds = Object.keys(tripsSelectionMap);

      if (offerId) {
        state.selectedOffers[journeyType].tripsSelectionMap[offerId] =
          selections;
      } else {
        state.selectedOffers[journeyType].tripsSelectionMap =
          selectedOfferIds.reduce(
            (selectionMap, offerId) => ({
              ...selectionMap,
              [offerId]: selections,
            }),
            {}
          );
      }
    })
    .addCase(unselectOffer, (state, action) => {
      const { offerId, journeyType } = action.payload;
      const { trips, tripsSelectionMap } = state.selectedOffers[journeyType];
      state.selectedOffers[journeyType].trips = trips.filter(
        ({ id }) => id !== offerId
      );
      state.selectedOffers[journeyType].tripsSelectionMap = _omit(
        tripsSelectionMap,
        offerId
      );
    })
    .addCase(payByLink.fulfilled, (state) => {
      state.paymentStatus = PaymentStatus.pending;
    })
    .addCase(getPaymentStatus.fulfilled, (state, { payload }) => {
      state.paymentStatus = payload;
    })
    .addCase(clearPaymentStatus, (state) => {
      state.paymentStatus = undefined;
    })
    .addMatcher(
      (action) =>
        [clearOfferSelection.type, unselectOffer.type].includes(action.type),
      (state, action) => {
        const {
          offerId,
          journeyType,
        }: { offerId: string; journeyType: 'inbound' | 'outbound' } =
          action.payload;
        state.selectedOffers[journeyType].tripsSelectionMap = _omit(
          state.selectedOffers[journeyType].tripsSelectionMap,
          offerId
        );
      }
    )
    .addMatcher(
      (action) =>
        [
          searchTrips.pending.type,
          showTripsResultPage.pending.type,
          resetSearch.type,
        ].includes(action.type),
      (state) => {
        state.travelPasses = [];
        state.inbound = [];
        state.outbound = [];
        state.selectedOffers = initialState.selectedOffers;
        state.warnings = [];
      }
    )
    .addMatcher(
      (action) =>
        [
          searchExchangeUpgradeOffers.fulfilled.type,
          searchExchangeOffers.fulfilled.type,
          searchTrips.fulfilled.type,
          showTripsResultPage.fulfilled.type,
        ].includes(action.type),
      (state, action) => {
        const {
          nextAvailableDepartureDate,
          journeyType,
          links,
          warnings,
        }: {
          nextAvailableDepartureDate: string;
          journeyType: 'inbound' | 'outbound';
          links: Array<JourneyLink>;
          warnings: Warnings;
        } = action.payload;
        state[journeyType] = action.payload.journeys;
        state.links = links;
        state.nextAvailableDepartureDate = nextAvailableDepartureDate;
        state.warnings = warnings?.warnings;
      }
    );
});
