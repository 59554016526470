import { Button, Icon, Loader } from '@fleet/shared';
import { Stack, Typography } from '@mui/material';
import { ModifyJourneyStepsProvider } from 'components/ModifyJourneyStepsProvider';
import { PassengerData, PassengersTable } from 'components/PassengersTable';
import { BookingTripWithAdmissions } from 'dto/booking';
import { cancelExchangeOperations } from 'features/booking/bookingActions';
import {
  bookingPartsSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { bookingModifyLoadingSelector } from 'features/loading/loadingSelectors';
import { resetSearch } from 'features/trip/tripActions';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransParagraph } from 'i18n/trans/paragraph';
import _isEqual from 'lodash/isEqual';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useAlert } from 'react-alert';
import { ModalWrap } from 'routes/bookingDetails/modal/ModalWrap';
import { useDispatch, useSelector } from 'store/utils';
import { prepareTrips } from 'utils/overview';
import {
  getPassengersNames,
  getTripAdmissions,
  isAdmissionInactive,
} from 'utils/trip';

interface ModifySelectionModalProps {
  passengerIds: Array<string>;
  passengers: Array<PassengerData>;
  onClose: () => void;
  updatePassengerSelection: (selectedPassengerIds: Array<string>) => void;
}

export const ModifySelectionModal: FC<ModifySelectionModalProps> = ({
  onClose,
  passengers,
  passengerIds,
  updatePassengerSelection,
}) => {
  const alert = useAlert();
  const loading = useSelector(bookingModifyLoadingSelector);
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector)!;
  const isModifyInProgress = useMemo(
    () => !!booking!.exchangeOperations.length,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );
  const [type, setType] = useState<'journey' | 'seating' | undefined>(
    isModifyInProgress ? 'seating' : undefined
  );
  const selection = useSelector(bookingPartsSelector, _isEqual);
  const handleClose = useCallback(async () => {
    onClose();
    await dispatch(cancelExchangeOperations());
  }, [dispatch, onClose]);

  const selectedTrips = useMemo<Array<BookingTripWithAdmissions>>(() => {
    const selectedAdmissionIds = Object.values(selection.admission).flat();
    return prepareTrips(booking.bookedTrips).filter((trip) =>
      getTripAdmissions(trip).find(
        ({ id, status }) =>
          !isAdmissionInactive({ status }) && selectedAdmissionIds.includes(id)
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const seatingEditDisabled = useMemo(
    () => selectedTrips.length > 1,
    [selectedTrips.length]
  );
  const journeyEditDisabled = useMemo(
    () => seatingEditDisabled && passengers.length > 1,
    [passengers.length, seatingEditDisabled]
  );

  const modalTitle = useMemo(() => {
    if (!type)
      return (
        <Typography variant="subtitle">
          <TransButton i18nKey="modifySelected" />
        </Typography>
      );

    return (
      <Stack direction="row" alignItems="flex-end" spacing={1}>
        <Typography variant="h1" sx={{ lineHeight: 1 }}>
          <TransButton
            i18nKey={
              type === 'journey' ? 'modifyJourney' : 'modifySeatingAndAddons'
            }
          />
        </Typography>
        <Typography variant="body1">
          ({getPassengersNames(booking, ...passengerIds)})
        </Typography>
      </Stack>
    );
  }, [booking, passengerIds, type]);

  useEffect(() => {
    if (passengerIds.length > 1 && selectedTrips.length > 1) {
      alert.error(<TransAlert i18nKey="modifySeparately" />);
    }
  }, [alert, passengerIds.length, selectedTrips.length]);

  useEffect(() => {
    dispatch(resetSearch());
  }, [dispatch]);

  return (
    <ModalWrap
      open
      withFooter={!!type}
      sx={{
        '&.MuiModal-root': {
          zIndex: 1299,
        },
      }}
      onClose={handleClose}
      title={modalTitle}
      {...(type
        ? {
            fullScreen: true,
            maxWidth: 'xl',
          }
        : {
            maxWidth: 'md',
          })}
    >
      <Loader active={loading} size="fullscreen" />
      {!type && (
        <Stack spacing={4}>
          <Typography>
            <TransParagraph i18nKey="modifySelection" />
          </Typography>
          <PassengersTable
            data={passengers}
            selectedTrips={selectedTrips}
            hiddenColumns={['status', 'tags', 'totalPrice']}
            onRowSelectionUpdate={updatePassengerSelection}
          />
          <Stack direction="row" spacing={1} justifyContent="flex-end">
            <Button
              variant="text"
              label={<TransButton i18nKey="cancel" />}
              onClick={onClose}
            />
            <Button
              variant="contained"
              label={<TransButton i18nKey="modifySeatingAndAddons" />}
              disabled={seatingEditDisabled}
              onClick={() => setType('seating')}
              startIcon={<Icon name="edit" />}
            />
            <Button
              variant="contained"
              label={<TransButton i18nKey="modifyJourney" />}
              disabled={journeyEditDisabled}
              onClick={() => setType('journey')}
              startIcon={<Icon name="edit" />}
            />
          </Stack>
        </Stack>
      )}
      {!!type && (
        <ModifyJourneyStepsProvider
          selectedTrips={selectedTrips}
          type={type}
          closeModal={handleClose}
          passengerIds={passengerIds}
        />
      )}
    </ModalWrap>
  );
};
