import { FC, useCallback, useMemo, useState } from 'react';
import { Icon, Loadable, Modal } from '@fleet/shared';
import { Button } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import { TransTitle } from 'i18n/trans/title';
import { makeStyles } from '@mui/styles';
import { useDispatch, useSelector } from 'store/utils';
import {
  bookingExpiredSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import {
  prepareTrips,
  useConfirmationSend,
  usePurchaserInfo,
  useShowValidations,
  useSubmitBooking,
} from 'utils/overview';
import { JourneyOverview } from 'routes/tickets/checkout/JourneyOverview';
import { PayerDetailsForm } from 'components/PayerDetailsForm';
import { useForm } from '@fleet/shared/form';
import { PurchaserDetailsPayload } from 'features/trip/tripActions';
import { TicketFulfillment } from 'components/ticketFulfillment/TicketFulfillment';
import { PaymentMethods } from 'components/PaymentMethods';
import { bookingCheckoutLoadingSelector } from 'features/loading/loadingSelectors';
import { Divider, Stack, Typography } from '@mui/material';
import { TransField } from 'i18n/trans/field';
import { getBooking } from 'features/booking/bookingActions';
import { PayByLinkModal } from 'routes/tickets/checkout/PayByLinkModal';
import {
  downloadPdfFlagSelector,
  paymentStatusSelector,
} from 'features/trip/tripSelector';
import { noop } from '@fleet/shared/utils/noop';
import { useAlert } from 'react-alert';
import { TransAlert } from 'i18n/trans/alert';
import { TicketSelectionPayload } from 'routes/tickets/checkout/Overview';
import { downloadBookingTickets } from 'utils/trip';

const useStyles = makeStyles(
  () => ({
    paper: {
      margin: 0,
      maxWidth: 'none',
      width: '900px',
    },
    '&.MuiDialogActions-root': {
      boxShadow: '0px 20px 32px 0px rgba(0, 0, 0, 0.5)',
    },
  }),
  { name: 'CheckoutOverviewModal' }
);

interface CheckoutOverviewModalProps {
  onClose: () => void;
  isOpen: boolean;
}

export const CheckoutOverviewModal: FC<CheckoutOverviewModalProps> = ({
  onClose,
  isOpen,
}) => {
  const [selectedPassenger, setSelectedPassenger] = useState<string>('');
  const [isEmailSelected, setEmailSelected] = useState(false);
  const [isSmsSelected, setSmsSelected] = useState(false);
  const dispatch = useDispatch();
  const alert = useAlert();
  const loading = useSelector(bookingCheckoutLoadingSelector);
  const classes = useStyles();
  const paymentStatus = useSelector(paymentStatusSelector);
  const [paymentMethod, setPaymentMethod] = useState('');
  const booking = useSelector(currentBookingSelector)!;
  const isBookingExpired = useSelector(bookingExpiredSelector);
  const downloadPdf = useSelector(downloadPdfFlagSelector);
  const preparedTrips = useMemo(
    () => prepareTrips(booking.bookedTrips),
    [booking]
  );
  const totalToPay = useMemo(() => {
    if (booking?.provisionalPrice) {
      return `${booking.provisionalPrice.amount} ${booking.provisionalPrice.currency}`;
    }
  }, [booking]);
  const handleConfirmationSend = useConfirmationSend(booking.id);
  const { form: ticketFulfillmentForm } = useForm<TicketSelectionPayload>({
    onSubmit: handleConfirmationSend,
    subscription: { invalid: true },
  });
  const onSuccess = useCallback(async () => {
    const updatedBooking = await dispatch(getBooking(booking.id)).unwrap();
    alert.success(
      <TransAlert i18nKey="bookingIsPaid" values={{ id: booking.id }} />
    );
    downloadPdf && downloadBookingTickets(updatedBooking);
    onClose();
  }, [booking, dispatch, alert, onClose, downloadPdf]);
  const onSubmit = useSubmitBooking(
    booking.id,
    ticketFulfillmentForm,
    paymentMethod,
    onSuccess
  );
  const {
    form: payerDetailsForm,
    handleSubmit,
    values,
    invalid,
  } = useForm<PurchaserDetailsPayload>({
    subscription: { values: true, invalid: true },
    onSubmit,
  });
  const handleOnClose = useCallback(() => {
    setPaymentMethod('');
    payerDetailsForm.restart();
    ticketFulfillmentForm.restart();
    onClose();
  }, [onClose, ticketFulfillmentForm, payerDetailsForm]);
  const purchaserInfo = usePurchaserInfo(payerDetailsForm, values);
  const showValidations = useShowValidations(payerDetailsForm, invalid);

  return (
    <Modal
      classes={{
        paper: classes.paper,
      }}
      title={<TransTitle i18nKey="paySelectedAdmissions" />}
      open={isOpen}
      onClose={handleOnClose}
      actionButton={
        <>
          <Button
            variant="text"
            onClick={() => {
              payerDetailsForm.restart();
              setSelectedPassenger('');
              setEmailSelected(false);
              setSmsSelected(false);
            }}
            label={<TransButton i18nKey="resetFields" />}
            disabled={isBookingExpired}
          />
          <Button
            variant="contained"
            form="purchaserDetails"
            type="submit"
            disabled={isBookingExpired || !paymentMethod}
            startIcon={<Icon name="check" />}
          >
            <TransButton i18nKey="confirmTransaction" />
          </Button>
        </>
      }
    >
      <Loadable loading={loading}>
        <Stack spacing={2} divider={<Divider />}>
          {preparedTrips.map((trip, idx) => (
            <JourneyOverview
              isOverview
              trip={trip}
              key={trip.id}
              canDelete={!!idx}
            />
          ))}
          <PayerDetailsForm
            selectedPassenger={selectedPassenger}
            setSelectedPassenger={setSelectedPassenger}
            form={payerDetailsForm}
            handleSubmit={handleSubmit}
          />
          <TicketFulfillment
            ticketFulfillmentForm={ticketFulfillmentForm}
            isPassengerDetailsFormInvalid={invalid}
            showValidations={showValidations}
            purchaserInfo={purchaserInfo}
            isSmsSelected={isSmsSelected}
            setSmsSelected={setSmsSelected}
            setEmailSelected={setEmailSelected}
            isEmailSelected={isEmailSelected}
          />
          <PaymentMethods
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
          />
          {!!paymentStatus && <PayByLinkModal onPaymentSuccess={() => noop} />}
          <Stack direction="row" alignItems="center">
            <Icon name="cart" size="24px" />
            <Typography variant="h2" sx={{ mr: '8px' }}>
              <TransField i18nKey="total" />:
            </Typography>
            <Typography variant="h2">{totalToPay}</Typography>
          </Stack>
        </Stack>
      </Loadable>
    </Modal>
  );
};
