import { RadioGroup } from '@fleet/shared/mui';
import { Typography } from '@mui/material';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import { Dispatch, FC, SetStateAction } from 'react';
import { IS_IMS_AT } from 'utils/flags';

interface PaymentMethodOverviewProps {
  paymentMethod: string;
  setPaymentMethod: Dispatch<SetStateAction<string>>;
}

export const PaymentMethods: FC<PaymentMethodOverviewProps> = ({
  paymentMethod,
  setPaymentMethod,
}) => {
  return (
    <>
      <Typography variant="h2">
        <TransTitle i18nKey="paymentMethod" />
      </Typography>
      <RadioGroup
        inline
        options={[
          { value: 'external', label: <TransLabel i18nKey="external" /> },
          ...(IS_IMS_AT
            ? [
                {
                  value: 'payByLink',
                  label: <TransLabel i18nKey="payByLink" />,
                },
              ]
            : []),
        ]}
        onChange={(val) => setPaymentMethod(val as unknown as string)}
        defaultValue={paymentMethod}
      />
    </>
  );
};
