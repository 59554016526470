import { api } from '@fleet/shared';
import { AxiosError } from 'axios';
import {
  BookedOffer,
  BookingDetailsPassenger,
  BookingTripWithAdmissions,
  BookingValidation,
} from 'dto/booking';
import { ErrorResponse } from 'dto/error';
import { TripLeg } from 'dto/trip';
import {
  sendConfirmation,
  setCurrentBooking,
  triggerBookingFulfillment,
} from 'features/booking/bookingActions';
import {
  payByLink,
  PurchaserDetailsPayload,
  updatePurchaserDetails,
} from 'features/trip/tripActions';
import { FormApi } from 'final-form';
import _groupBy from 'lodash/groupBy';
import _mapValues from 'lodash/mapValues';
import _partition from 'lodash/partition';
import { useCallback, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { TicketSelectionPayload } from 'routes/tickets/checkout/Overview';
import { useDispatch } from 'store/utils';
import { IS_IMS_AT } from 'utils/flags';
import { useTranslation } from 'react-i18next';
import { isAdmissionInactive } from 'utils/trip';

export const prefillPayerFormData = (
  passengerId: string,
  passengers: BookingDetailsPassenger[],
  form: FormApi<PurchaserDetailsPayload, Partial<PurchaserDetailsPayload>>
) => {
  const passenger = passengers.find(({ id }) => id === passengerId);
  if (passenger) {
    const { firstName, lastName, contactInformation } = passenger;
    form.batch(() => {
      form.change('firstName', firstName.value);
      form.change('lastName', lastName.value);
      form.change('email', contactInformation.emailAddress.value);
      // @ts-ignore
      form.change('phone.number', contactInformation.phoneNumber.value);
    });
  } else {
    form.reset({});
  }
};

export const usePurchaserInfo = (
  payerDetailsForm: FormApi<
    PurchaserDetailsPayload,
    Partial<PurchaserDetailsPayload>
  >,
  values: PurchaserDetailsPayload
) => {
  return useMemo(() => {
    let purchaserPhoneNumber: string | undefined;
    let purchaserEmail: string | undefined;
    const phoneNumberState = payerDetailsForm.getFieldState(
      'phone.number' as keyof PurchaserDetailsPayload
    );

    if (payerDetailsForm.getFieldState('email')?.valid) {
      purchaserEmail = values.email;
    }

    if (phoneNumberState?.valid) {
      purchaserPhoneNumber = values.phone?.number;
    }

    return { purchaserEmail, purchaserPhoneNumber };
  }, [payerDetailsForm, values]);
};

export const useShowValidations = (
  form: FormApi<PurchaserDetailsPayload, Partial<PurchaserDetailsPayload>>,
  invalid: boolean
) => {
  return useCallback(() => {
    if (invalid) form.submit();
  }, [form, invalid]);
};

export const prepareTrips = (
  trips?: Array<BookingTripWithAdmissions>
): Array<BookingTripWithAdmissions> => {
  if (!trips) return [];
  const tripsByJourneyRef = _mapValues(
    _groupBy(trips, 'journeyRef'),
    (trips) => [
      ...trips.sort(
        (a, b) =>
          new Date(a.departureTime).getTime() -
          new Date(b.departureTime).getTime()
      ),
    ]
  );
  const journeys = Object.keys(tripsByJourneyRef);
  return journeys
    .map((ref) => {
      const journeyTrips = tripsByJourneyRef[ref].filter(
        ({ bookedOffers }) =>
          !bookedOffers.every(({ admissions }) =>
            admissions.every(isAdmissionInactive)
          )
      );
      const [outboundTrips, inboundTrips] = _partition(
        journeyTrips,
        ({ isOutbound }) => isOutbound
      );

      return [outboundTrips, inboundTrips]
        .filter((arr) => arr.length)
        .map((trips) => {
          const [firstJourneyTrip, ...restJourneyTrips] = trips;
          const legs = trips.reduce<Array<TripLeg>>(
            (legs, trip) => [...legs, ...trip.legs],
            []
          );

          return {
            ...firstJourneyTrip,
            bookedOffers: [
              ...firstJourneyTrip.bookedOffers,
              ...restJourneyTrips.reduce<Array<BookedOffer>>(
                (acc, { bookedOffers }) => [...acc, ...bookedOffers],
                []
              ),
            ],
            legs,
            arrivalTime: trips[trips.length - 1].arrivalTime,
            destinationStop: trips[trips.length - 1].destinationStop,
          };
        });
    })
    .flat();
};

export const useConfirmationSend = (bookingId: string) => {
  const dispatch = useDispatch();
  return useCallback(
    async ({
      emailConfirmationRecipient = [],
      additionalEmailConfirmationRecipients = [],
      smsConfirmationRecipient = [],
      additionalSmsConfirmationRecipients = [],
      passengerSelection = [],
      includeTickets,
    }: TicketSelectionPayload) => {
      const emailConfirmationRecipients = [
        ...emailConfirmationRecipient,
        ...additionalEmailConfirmationRecipients,
      ];
      const smsConfirmationRecipients = [
        ...smsConfirmationRecipient,
        ...additionalSmsConfirmationRecipients,
      ];
      await Promise.all([
        ...(emailConfirmationRecipients.length ||
        smsConfirmationRecipients.length
          ? [
              dispatch(
                sendConfirmation({
                  url: 'purchase-confirmation',
                  bookingId,
                  includeTickets,
                  emailsOverride: emailConfirmationRecipients,
                  phoneNumbersOverride: smsConfirmationRecipients.map(
                    (phoneNo) => ({
                      number: phoneNo,
                    })
                  ),
                })
              ),
            ]
          : []),
        ...(passengerSelection.length
          ? [
              dispatch(
                sendConfirmation({
                  url: 'ticket-delivery',
                  bookingId,
                  passengers: passengerSelection.map((passengerId: string) => ({
                    passengerId,
                  })),
                })
              ),
            ]
          : []),
      ]);
    },
    [bookingId, dispatch]
  );
};

export const useSubmitBooking = (
  bookingId: string,
  ticketFulfillmentForm: FormApi<TicketSelectionPayload>,
  paymentMethod: string,
  showSuccessPage: () => void
) => {
  const dispatch = useDispatch();
  const alert = useAlert();

  const hasRecipients = useCallback(() => {
    const formState = ticketFulfillmentForm.getState().values;
    return (
      formState.passengerSelection?.length > 0 ||
      formState.emailConfirmationRecipient?.length > 0 ||
      formState.additionalEmailConfirmationRecipients?.length > 0 ||
      formState.smsConfirmationRecipient?.length > 0 ||
      formState.additionalSmsConfirmationRecipients?.length > 0
    );
  }, [ticketFulfillmentForm]);

  return useCallback(
    async (values) => {
      if (ticketFulfillmentForm.getState().invalid)
        return ticketFulfillmentForm.submit();
      if (IS_IMS_AT) {
        const validations = (
          await api.post<{ notFulfilledValidations: Array<BookingValidation> }>(
            `/bookings/${bookingId}/validate`,
            { scope: ['UMNR'] }
          )
        ).data.notFulfilledValidations;
        if (validations?.length) {
          return validations.forEach(({ detail }) => alert.error(detail));
        }
      }

      await dispatch(
        updatePurchaserDetails({
          bookingId,
          ...values,
        })
      ).unwrap();
      let updatedBooking;

      if (paymentMethod === 'payByLink') {
        try {
          await dispatch(
            payByLink({
              bookingId,
              firstName: values.firstName,
              lastName: values.lastName,
              email: values.email,
              phoneNumber: values.phone.number,
            })
          ).unwrap();
        } catch (e) {
          if (
            (e as AxiosError<ErrorResponse>)?.response?.data?.errors?.find(
              ({ message }) => message === 'The booking is already confirmed'
            )
          ) {
            showSuccessPage();
          }
        }
      } else {
        updatedBooking = await dispatch(
          triggerBookingFulfillment(bookingId)
        ).unwrap();
        showSuccessPage();
      }

      if (hasRecipients()) {
        await ticketFulfillmentForm.submit();
      }

      updatedBooking && dispatch(setCurrentBooking(updatedBooking));
    },
    [
      ticketFulfillmentForm,
      dispatch,
      bookingId,
      paymentMethod,
      hasRecipients,
      alert,
      showSuccessPage,
    ]
  );
};

export const useValidatePhoneNumber = () => {
  const { t } = useTranslation();

  return useCallback(
    (value: string) => {
      if (value && !/^\+\d+$/.test(value)) {
        return t('field.invalidPhoneNumber', 'Invalid phone number format');
      }
      return null;
    },
    [t]
  );
};
