import {
  api,
  Button,
  CardHeader,
  DateField,
  formatDate,
  FormProvider,
  Layout,
  Loadable,
  RadioGroupField,
  useForm,
} from '@fleet/shared';
import { Grid, CardContent, Stack } from '@mui/material';
import download from 'downloadjs';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { stringify } from 'qs';
import { useCallback, useMemo, useState } from 'react';
import _mapValues from 'lodash/mapValues';

interface Payload {
  fileType: 'pdf' | 'xlsx';
  from: string;
  to?: string;
}
export const SalesReports = () => {
  const todayDate = useMemo(() => new Date().toISOString(), []);
  const [loading, setLoading] = useState(false);
  const onSubmit = useCallback(async (payload: Payload) => {
    const { fileType, from, to } = payload;
    setLoading(true);
    try {
      const fileData = (
        await api.get(
          `/reports/sales${stringify(
            {
              fileType,
              ..._mapValues(
                { from, to },
                (date) => date && formatDate(date, 'yyyy-MM-dd')
              ),
            },
            { addQueryPrefix: true }
          )}`,
          {
            responseType: 'arraybuffer',
          }
        )
      ).data;

      download(
        fileData,
        `Sales_report_${formatDate(new Date(), 'dd.MM.yyyy_HH.mm')}.${fileType}`
      );
    } catch (e) {}
    setLoading(false);
  }, []);
  const { form, handleSubmit } = useForm<Payload>({
    initialValues: {
      from: todayDate,
      to: todayDate,
      fileType: 'pdf',
    },
    onSubmit,
  });

  return (
    <Loadable loading={loading}>
      <Layout
        header={
          <CardHeader
            sx={{ padding: '16px 24px' }}
            title={<TransSubtitle i18nKey="salesReports" />}
          />
        }
      >
        <FormProvider form={form}>
          <CardContent component="form" onSubmit={handleSubmit}>
            <Grid container columns={3} spacing={4} rowSpacing={1}>
              <Grid item xs={1}>
                <DateField
                  name="from"
                  required
                  label={<TransField i18nKey="dateFrom" />}
                />
              </Grid>
              <Grid item xs={1}>
                <DateField name="to" label={<TransField i18nKey="dateTo" />} />
              </Grid>
              <Grid item xs={1}>
                <RadioGroupField
                  name="fileType"
                  label={<TransField i18nKey="fileFormat" />}
                  options={[
                    {
                      value: 'pdf',
                      label: 'PDF',
                    },
                    {
                      value: 'xlsx',
                      label: 'Excel',
                    },
                  ]}
                  inline
                />
              </Grid>
              <Grid item xs={3}>
                <Stack direction="row" justifyContent="flex-end">
                  <Button
                    variant="text"
                    label={<TransButton i18nKey="resetFields" />}
                    onClick={() => form.reset()}
                  />
                  <Button
                    label={<TransButton i18nKey="download" />}
                    type="submit"
                  />
                </Stack>
              </Grid>
            </Grid>
          </CardContent>
        </FormProvider>
      </Layout>
    </Loadable>
  );
};
