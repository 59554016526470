import { Button, Icon } from '@fleet/shared';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { Card, Stack, Typography } from '@mui/material';
import { SearchTab, SearchTabsContext } from 'components/SearchTabsContext';
import { TripSearchParams } from 'dto/trip';
import { resetCurrentBooking } from 'features/booking/bookingActions';
import {
  currentBookingSelector,
  onHoldBookingTimeSelector,
} from 'features/booking/bookingSelectors';
import { resetSearch } from 'features/trip/tripActions';
import { downloadPdfFlagSelector } from 'features/trip/tripSelector';
import { TransButton } from 'i18n/trans/button';
import { TransParagraph } from 'i18n/trans/paragraph';
import { TransTitle } from 'i18n/trans/title';
import { FC, useCallback, useContext, useEffect } from 'react';
import { useForm } from 'react-final-form';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'store/utils';
import { downloadBookingTickets } from 'utils/trip';
import { v4 } from 'uuid';
import { makeStyles } from '@mui/styles';

let downloadTimeout: undefined | ReturnType<typeof setTimeout>;

const useStyles = makeStyles(
  (theme) => ({
    link: {
      textDecoration: 'underline',
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  }),
  { name: 'BookingSuccess' }
);

interface BookingSuccessProps extends RouteComponentProps {
  initBooking: (tab: SearchTab, onReset: SearchTabsContext['resetTab']) => void;
}
export const BookingSuccess: FC<BookingSuccessProps> = ({
  history,
  initBooking,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const onHoldBookingTime = useSelector(onHoldBookingTimeSelector);
  const downloadPdf = useSelector(downloadPdfFlagSelector);
  const booking = useSelector(currentBookingSelector);
  const form = useForm<TripSearchParams>();
  const { currentTab, resetTab, closeTab, activeTabIdx } =
    useContext(SearchTabsContext);
  const downloadTicket = useCallback(() => {
    downloadPdf && downloadBookingTickets(booking);
  }, [booking, downloadPdf]);

  useEffect(() => {
    initBooking(currentTab!, resetTab);
    return () => {
      closeTab(activeTabIdx);
      dispatch(resetCurrentBooking());
      dispatch(resetSearch());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabIdx]);

  useEffect(() => {
    if (!booking) return;
    downloadTimeout = setTimeout(() => downloadTicket(), 5000);
    return () => clearTimeout(downloadTimeout);
  }, [booking, downloadTicket]);

  const onButtonDownload = useCallback(() => {
    clearTimeout(downloadTimeout);
    downloadTicket();
  }, [downloadTicket]);

  const redirectToBookingDetails = useCallback(() => {
    history.replace(`/booking/${booking?.id}`);
  }, [booking?.id, history]);

  return (
    <Card sx={{ px: 4, py: 10 }}>
      <Stack
        sx={{ maxWidth: 448, m: 'auto' }}
        alignItems="center"
        justifyContent="center"
        spacing={3}
      >
        {booking?.status === 'ON_HOLD' ? (
          <>
            <Icon size={34} name="save" />
            <Typography variant="title">
              <TransTitle
                i18nKey="putBookingOnHoldWithLink"
                values={{ reference: booking?.code }}
              >
                Booking
                <span
                  className={classes.link}
                  onClick={redirectToBookingDetails}
                />
                on hold
              </TransTitle>
            </Typography>
            <Typography variant="body1" textAlign="center">
              <TransParagraph
                i18nKey="onHoldSuccess"
                values={{
                  date:
                    onHoldBookingTime &&
                    formatDate(onHoldBookingTime, currentDateTimeFormat),
                }}
              />
            </Typography>
            <Button
              fullWidth
              onClick={redirectToBookingDetails}
              label={<TransButton i18nKey="viewBooking" />}
            />
          </>
        ) : (
          <>
            <Icon size={34} name="check" />
            <Typography variant="title">
              <TransTitle
                i18nKey="bookingCompleteWithLink"
                values={{ reference: booking?.code }}
              >
                Booking
                <span
                  className={classes.link}
                  onClick={redirectToBookingDetails}
                />
                complete
              </TransTitle>
            </Typography>
            <Typography variant="body1" textAlign="center">
              <TransParagraph i18nKey="bookingSuccess" />
            </Typography>
            <Button
              fullWidth
              startIcon={<Icon name="download" />}
              onClick={onButtonDownload}
              label={<TransButton i18nKey="download" />}
            />
          </>
        )}

        <Button
          fullWidth
          variant="outlined"
          onClick={() => {
            history.replace('/search');
            resetTab();
            form.restart({
              passengerSpecifications: [
                {
                  type: 'PERSON',
                  externalReference: v4(),
                },
              ],
              departureTime: formatDate(new Date()),
              promotionCodes: [],
              corporateCodes: [],
            });
          }}
          label={<TransButton i18nKey="backToSearch" />}
        />
      </Stack>
    </Card>
  );
};
