import {
  FieldArray,
  FormProvider,
  SwitchField,
  TextField,
  useFormContext,
} from '@fleet/shared';
import { CheckboxGroupField } from '@fleet/shared/form';
import { Icon } from '@fleet/shared/mui';
import {
  Button,
  Card,
  CardContent,
  Divider,
  Grid,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { alpha } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { currentBookingSelector } from 'features/booking/bookingSelectors';
import { selectPosConfiguration } from 'features/user/userSelector';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, SyntheticEvent, useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'store/utils';

interface EmailFulfillmentProps {
  formId?: string;
  purchaserEmail?: string;
  isInline?: boolean;
  isSaleFlow?: boolean;
  onSubmit?: (event?: SyntheticEvent<HTMLFormElement>) => void;
}

const useStyles = makeStyles(
  (theme) => ({
    root: {
      minWidth: '15rem',
    },
    sectionHeader: {
      '& + *': {
        marginTop: '1rem',
      },
    },
    indicator: {
      height: 1,
    },
    hidden: {
      display: 'none',
    },
    tabs: {
      padding: '0 1rem',
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    bgGray: {
      background: theme.palette.background.default,
    },
    addBtn: {
      paddingLeft: 0,
      alignSelf: 'flex-start',
      color: theme.palette.primary.main,
      background: alpha(theme.palette.action.hover, 0.05),
      '&:hover': {
        background: alpha(theme.palette.action.hover, 0.2),
      },
    },
    ticketsSwitch: {
      '& .MuiFormControl-root': {
        width: 'auto',
      },
    },
    selectionControl: {
      minWidth: 'none',
      fontWeight: 'bold',
      color: theme.palette.text.secondary,
      padding: 0,
    },
  }),
  { name: 'SendTicketsSelection' }
);

export const EmailFulfillment: FC<EmailFulfillmentProps> = ({
  formId,
  purchaserEmail,
  isInline,
  onSubmit,
  isSaleFlow,
}) => {
  const form = useFormContext();
  const posConfiguration = useSelector(selectPosConfiguration);
  const classes = useStyles();
  const { purchaser, passengers } = useSelector(currentBookingSelector)!;
  const purchaserOptions = useMemo(() => {
    const email =
      purchaserEmail ?? purchaser.contactInformation.emailAddress.value;
    return [
      {
        label: (
          <TransSubtitle
            i18nKey="sendToPayer"
            values={{
              email,
            }}
          />
        ),
        value: email,
      },
    ];
  }, [purchaser.contactInformation.emailAddress.value, purchaserEmail]);

  useEffect(() => {
    if (!posConfiguration || !posConfiguration.purchaseConfirmationEnabled)
      return;

    const initialFields = {
      includeTickets: !!isSaleFlow,
      emailConfirmationRecipient: isSaleFlow ? [purchaserOptions[0].value] : [],
      passengerSelection: [],
    };

    Object.entries(initialFields).forEach(([fieldName, value]) => {
      form.change(fieldName, value);
    });
  }, [form, purchaserOptions, posConfiguration, isSaleFlow]);

  const passengerOptions = useMemo(
    () =>
      passengers.map(
        ({
          firstName,
          lastName,
          contactInformation: { emailAddress },
          id,
        }) => ({
          label: `${firstName.value} ${lastName.value} (${emailAddress.value})`,
          value: id,
        })
      ),
    [passengers]
  );

  const getPassengerSelectionHandler = useCallback(
    (selection: 'all' | 'none') => () => {
      form.change(
        'passengerSelection',
        selection === 'all' ? passengers.map(({ id }) => id) : []
      );
    },
    [form, passengers]
  );

  return (
    <Card elevation={0} className={classes.root}>
      <FormProvider form={form}>
        <CardContent
          id={formId}
          component="form"
          className={classNames({ [classes.bgGray]: isInline })}
          onSubmit={onSubmit}
        >
          <Typography variant="subtitle" className={classes.sectionHeader}>
            <TransSubtitle i18nKey="sendBookingConfirmationViaEmail" />
          </Typography>
          <Grid container columns={2} spacing={1}>
            {posConfiguration?.purchaseConfirmationEnabled && (
              <Grid item xs={1}>
                <Typography
                  variant="subtitle"
                  color="primary.main"
                  className={classes.sectionHeader}
                >
                  <TransSubtitle i18nKey="emailRecipient" />
                </Typography>
                <Stack spacing={2} divider={<Divider />}>
                  <CheckboxGroupField
                    name="emailConfirmationRecipient"
                    options={purchaserOptions}
                  />
                  <FieldArray name="additionalEmailConfirmationRecipients">
                    {({ fields }) => (
                      <Stack spacing={2}>
                        {fields.value?.map((_, idx) => (
                          <Stack direction="row" spacing={1} key={idx}>
                            <TextField
                              required
                              email
                              name={`${fields.name}[${idx}]`}
                              label={
                                <TransField i18nKey="additionalEmailRecipient" />
                              }
                            />
                            <IconButton
                              sx={{ alignSelf: 'flex-start', top: '24px' }}
                              onClick={() => fields.remove(idx)}
                            >
                              <Icon name="close" />
                            </IconButton>
                          </Stack>
                        ))}
                        <Button
                          className={classes.addBtn}
                          startIcon={<Icon name="add" />}
                          variant="contained"
                          onClick={() => fields.push('')}
                        >
                          <TransButton i18nKey="addRecipient" />
                        </Button>
                      </Stack>
                    )}
                  </FieldArray>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    className={classes.ticketsSwitch}
                  >
                    <Typography>
                      <TransField i18nKey="includeAllTickets" />
                    </Typography>
                    <SwitchField name="includeTickets" />
                  </Stack>
                </Stack>
              </Grid>
            )}
            {posConfiguration?.ticketDeliveryEnabled && (
              <Grid item xs={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  className={classes.sectionHeader}
                >
                  <Typography variant="subtitle" color="primary.main">
                    <TransButton i18nKey="sendTicketsToPassengers" />
                  </Typography>
                  <Stack direction="row" spacing={1}>
                    <Button
                      variant="text"
                      className={classes.selectionControl}
                      onClick={getPassengerSelectionHandler('all')}
                    >
                      <TransButton i18nKey="selectAll" />
                    </Button>
                    <Button
                      variant="text"
                      className={classes.selectionControl}
                      onClick={getPassengerSelectionHandler('none')}
                    >
                      <TransButton i18nKey="none" />
                    </Button>
                  </Stack>
                </Stack>
                <CheckboxGroupField
                  name="passengerSelection"
                  options={passengerOptions}
                />
              </Grid>
            )}
          </Grid>
        </CardContent>
      </FormProvider>
    </Card>
  );
};
