import {
  FieldArray,
  FormProvider,
  Modal,
  SelectField,
  useForm,
} from '@fleet/shared';
import { useModal } from '@fleet/shared/hooks';
import { Button } from '@fleet/shared/mui';
import { Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { ModifyJourneyStepsContext } from 'components/ModifyJourneyStepsProvider';
import { SearchTabsContext } from 'components/SearchTabsContext';
import { TagGroup } from 'components/Tag';
import { PRM_NEEDS, SeatProperty } from 'dto/booking';
import { PassengerOfferSelection, TripOffer } from 'dto/trip';
import {
  clearOfferSelection,
  updateOfferSelection,
} from 'features/trip/tripActions';
import {
  selectSelectedOffers,
  tripsSelector,
} from 'features/trip/tripSelector';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransTitle } from 'i18n/trans/title';
import _uniqBy from 'lodash/uniqBy';
import { FC, useCallback, useContext, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useDispatch, useSelector } from 'store/utils';
import { getReadablePropertyName } from 'utils/trip';

interface SeatPreferenceModalProps {
  offer: TripOffer;
  onClose: () => void;
}

type Selection = PassengerOfferSelection & {
  placePropertiesStr: string;
};

interface SeatPreferenceForm {
  selections: Array<Selection>;
}

const useStyles = makeStyles(
  (theme) => ({
    paper: {
      margin: 0,
      maxWidth: 'none',
      width: 776,
    },
    number: {
      color: theme.palette.text.secondary,
      '& span': {
        color: theme.palette.text.primary,
        marginRight: 4,
      },
    },
  }),
  { name: 'SeatPreferenceModal' }
);

const FORM_ID = 'seat-preferences';

export const SeatPreferenceModal: FC<SeatPreferenceModalProps> = ({
  offer,
  onClose,
}) => {
  const { outbound: selectedOutboundOffers, inbound: selectedInboundOffers } =
    useSelector(selectSelectedOffers);
  const { reservationLegCoverage, reservationOfferParts, id } = offer;
  const { open } = useModal({ open: true });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { currentTab } = useContext(SearchTabsContext);
  const { modifyPassengerSpecifications } = useContext(
    ModifyJourneyStepsContext
  );
  const { inboundTrips } = useSelector(tripsSelector);
  const isRoundTrip = !!inboundTrips.length;
  const hasSelectedAnyOffer = isRoundTrip
    ? !!selectedInboundOffers.trips.length &&
      !!selectedOutboundOffers.trips.length
    : !!selectedOutboundOffers.trips.length;
  const journeyType = selectedOutboundOffers.trips.some(
    (offer) => offer.id === id
  )
    ? 'outbound'
    : 'inbound';

  const currentOfferSelections = useMemo(() => {
    let selections = selectedOutboundOffers.tripsSelectionMap[id]?.map(
      ({ placeProperties, ...rest }) => ({
        placePropertiesStr: placeProperties.join(','),
        placeProperties,
        ...rest,
      })
    );
    if (isRoundTrip) {
      const inboundSelections = selectedInboundOffers.tripsSelectionMap[
        id
      ]?.map(({ placeProperties, ...rest }) => ({
        placePropertiesStr: placeProperties.join(','),
        placeProperties,
        ...rest,
      }));
      if (inboundSelections) {
        selections = selections.concat(inboundSelections);
      }
    }
    return selections;
  }, [
    id,
    selectedInboundOffers.tripsSelectionMap,
    selectedOutboundOffers.tripsSelectionMap,
    isRoundTrip,
  ]);

  const passengerSpecifications = useMemo(
    () =>
      modifyPassengerSpecifications ??
      currentTab!.params!.passengerSpecifications!,
    [currentTab, modifyPassengerSpecifications]
  );

  const onSubmit = useCallback(
    (payload: SeatPreferenceForm) => {
      const preparedSelections = payload.selections
        .filter(({ placePropertiesStr }) => placePropertiesStr)
        .map(({ placePropertiesStr, ...rest }) => ({
          ...rest,
          placeProperties: placePropertiesStr?.split(',') ?? [],
        })) as Array<PassengerOfferSelection>;
      const emptySelection = preparedSelections.every(
        ({ placeProperties }) => !placeProperties?.length
      );
      if (emptySelection) {
        dispatch(clearOfferSelection({ offerId: offer.id, journeyType }));
      } else {
        dispatch(
          updateOfferSelection({
            journeyType,
            offerId: offer.id,
            selections: preparedSelections,
          })
        );
      }
      onClose();
    },
    [dispatch, offer.id, onClose, journeyType]
  );

  const initialValues = useMemo<SeatPreferenceForm>(() => {
    const { tripId, legId, ...rest } = reservationLegCoverage[0];
    return {
      selections:
        currentOfferSelections ??
        passengerSpecifications.map(({ externalReference }, idx) => ({
          passengerIds: [externalReference],
          tripLegCoverage: {
            tripId,
            legId,
          },
          ...rest,
          reservationId: reservationOfferParts[idx].reservationId,
          placePropertiesStr: '',
        })),
    };
  }, [
    currentOfferSelections,
    passengerSpecifications,
    reservationLegCoverage,
    reservationOfferParts,
  ]);

  const { form, handleSubmit, values } = useForm<SeatPreferenceForm>({
    onSubmit,
    initialValues,
    subscription: { values: true },
  });
  const getPropertiesTranslation = useCallback(
    (placeProperties: SeatProperty[]) => {
      try {
        return placeProperties
          .map((property) => {
            try {
              return renderToString(<TransLabel i18nKey={property} />);
            } catch (e) {
              return getReadablePropertyName(property);
            }
          })
          .join(', ');
      } catch (e) {
        placeProperties.join(', ');
      }
    },
    []
  );
  const propertiesOptions = useMemo(() => {
    const options = reservationLegCoverage
      .filter(({ placeProperties }) => placeProperties?.length)
      .map(({ placeProperties }) => ({
        label: getPropertiesTranslation(placeProperties),
        value: placeProperties.join(','),
      }));
    return _uniqBy(options, 'value');
  }, [getPropertiesTranslation, reservationLegCoverage]);

  const applyCurrentPreferenceToOffers = useCallback(() => {
    dispatch(updateOfferSelection({ ...values, journeyType }));
  }, [dispatch, values, journeyType]);
  return (
    <Modal
      classes={{
        paper: classes.paper,
      }}
      title={<TransTitle i18nKey="seatPreference" />}
      open={open}
      onClose={onClose}
      showCloseControl={false}
      actionButton={
        <Stack direction="row" spacing={2}>
          {hasSelectedAnyOffer && (
            <Button variant="outlined" onClick={applyCurrentPreferenceToOffers}>
              <TransButton i18nKey="applySeatPreferenceToAll" />
            </Button>
          )}
          <Button variant="contained" form={FORM_ID} type="submit">
            <TransButton i18nKey="confirmSelection" />
          </Button>
        </Stack>
      }
    >
      <FormProvider form={form}>
        <Grid
          component="form"
          id={FORM_ID}
          container
          columns={2}
          spacing={3}
          rowSpacing={3}
          onSubmit={handleSubmit}
        >
          <FieldArray<PassengerOfferSelection> name="selections">
            {({ fields }) =>
              fields.value?.map((value, idx, values) => {
                const { passengerIds } = value;
                const prmNeeds = passengerSpecifications.find(
                  ({ externalReference }) =>
                    passengerIds.includes(externalReference)
                )!.prmNeeds;

                return (
                  <Grid item key={idx} xs={1}>
                    <Stack spacing={2}>
                      <Stack direction="row" justifyContent="space-between">
                        <Typography
                          variant="subtitle"
                          className={classes.number}
                        >
                          <span>{`${idx + 1}/${values.length}`}</span>
                          <TransLabel i18nKey="passenger" />
                        </Typography>

                        {prmNeeds && (
                          <TagGroup
                            title={<TransLabel i18nKey="prmNeed" />}
                            texts={prmNeeds.map((code) => (
                              <TransLabel i18nKey={code as PRM_NEEDS} />
                            ))}
                            color="action"
                            subColor="hoverText"
                            variant="body2"
                          />
                        )}
                      </Stack>

                      <SelectField
                        label={<TransField i18nKey="preference" />}
                        name={`${fields.name}[${idx}].placePropertiesStr`}
                        showEmptyOption
                        options={propertiesOptions.filter(({ value }) =>
                          prmNeeds
                            ? value.includes('WHEELCHAIR_AREA')
                            : !value.includes('WHEELCHAIR_AREA')
                        )}
                      />
                    </Stack>
                  </Grid>
                );
              })
            }
          </FieldArray>
        </Grid>
      </FormProvider>
    </Modal>
  );
};
