import { Icon, useModal } from '@fleet/shared';
import { useForm } from '@fleet/shared/form';
import { Button } from '@fleet/shared/mui';
import { Divider, Stack, Typography } from '@mui/material';
import { AlertCard } from 'components/AlertCard';
import { BookingTotalFee } from 'components/BookingTotalFee';
import { CartTotal } from 'components/CartTotal';
import { ModifyFees } from 'components/ModifyFees';
import { ModifyJourneyStepsContext } from 'components/ModifyJourneyStepsProvider';
import { OnHoldBookingModal } from 'components/OnHoldBookingModal';
import { PayerDetailsForm } from 'components/PayerDetailsForm';
import { PaymentMethods } from 'components/PaymentMethods';
import { SearchTabsContext } from 'components/SearchTabsContext';
import { TicketFulfillment } from 'components/ticketFulfillment/TicketFulfillment';
import { FulfillmentStatus } from 'dto/booking';
import {
  clearExchangeOperations,
  getBooking,
} from 'features/booking/bookingActions';
import {
  bookingExpiredSelector,
  currentBookingSelector,
  currentTripsSelector,
  isTravelPassBookingSelector,
} from 'features/booking/bookingSelectors';
import { bookingCheckoutLoadingSelector } from 'features/loading/loadingSelectors';
import { PurchaserDetailsPayload } from 'features/trip/tripActions';
import { paymentStatusSelector } from 'features/trip/tripSelector';
import { TransAlert } from 'i18n/trans/alert';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTitle } from 'i18n/trans/title';
import {
  FC,
  ReactNode,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { ModalControlsWrap } from 'routes/bookingDetails/modal/ModalControlsWrap';
import { ModalWrap } from 'routes/bookingDetails/modal/ModalWrap';
import { Comments } from 'routes/bookingDetails/tabs/Comments';
import { JourneyOverview } from 'routes/tickets/checkout/JourneyOverview';
import { PayByLinkModal } from 'routes/tickets/checkout/PayByLinkModal';
import { TravelPassOverview } from 'routes/tickets/checkout/TravelPassOverview';
import { useDispatch, useSelector } from 'store/utils';
import { IS_DS_AT, IS_IMS_AT } from 'utils/flags';
import {
  useConfirmationSend,
  usePurchaserInfo,
  useShowValidations,
  useSubmitBooking,
} from 'utils/overview';
import { getOnDemandServiceTexts, getTripAdmissions } from 'utils/trip';
import { useHistory } from 'react-router-dom';

export interface TicketSelectionPayload {
  includeTickets: boolean;
  emailConfirmationRecipient: Array<string>;
  additionalEmailConfirmationRecipients: Array<string>;
  smsConfirmationRecipient: Array<string>;
  additionalSmsConfirmationRecipients: Array<string>;
  passengerSelection: Array<string>;
}

interface OverviewProps {
  goToNextStep: () => void;
  isModifyFlow?: boolean;
  submitLabel?: ReactNode;
}

export const Overview: FC<OverviewProps> = ({
  goToNextStep,
  submitLabel,
  isModifyFlow,
}) => {
  const [selectedPassenger, setSelectedPassenger] = useState<string>('');
  const [isEmailSelected, setEmailSelected] = useState(false);
  const [isSmsSelected, setSmsSelected] = useState(false);
  const history = useHistory();
  const [paymentMethod, setPaymentMethod] = useState(
    IS_DS_AT ? 'external' : ''
  );
  const { open, onOpen, onClose } = useModal();
  const { closeModal } = useContext(ModifyJourneyStepsContext);
  const booking = useSelector(currentBookingSelector)!;
  const currentTrips = useSelector(currentTripsSelector);
  const showPaymentMethods = useMemo(() => {
    return (
      !isModifyFlow ||
      !booking.bookedTrips
        .map((trip) => getTripAdmissions(trip))
        .flat()
        .some(({ status }) => status === FulfillmentStatus.ON_HOLD)
    );
  }, [booking.bookedTrips, isModifyFlow]);
  const paymentStatus = useSelector(paymentStatusSelector);
  const dispatch = useDispatch();
  const isTravelPassBooking = useSelector(isTravelPassBookingSelector);
  const loading = useSelector(bookingCheckoutLoadingSelector);
  const isBookingExpired = useSelector(bookingExpiredSelector);
  const { updateTab } = useContext(SearchTabsContext);
  const { bookedTrips } = booking;
  const totalToPay = useMemo(() => {
    if (booking?.provisionalPrice) {
      return `${booking.provisionalPrice.amount} ${booking.provisionalPrice.currency}`;
    }
  }, [booking]);
  const handleConfirmationSend = useConfirmationSend(booking.id);
  const { form: ticketFulfillmentForm } = useForm<TicketSelectionPayload>({
    onSubmit: handleConfirmationSend,
    subscription: { invalid: true },
  });
  const showSuccessPage = useCallback(async () => {
    updateTab({ isCompleted: true });
    if (paymentMethod === 'payByLink') {
      dispatch(getBooking(booking.id));
    }
    goToNextStep();
  }, [booking.id, dispatch, goToNextStep, paymentMethod, updateTab]);
  const onBookingSubmit = useSubmitBooking(
    booking.id,
    ticketFulfillmentForm,
    paymentMethod,
    showSuccessPage
  );
  const onModifySubmit = useCallback(
    async (values) => {
      await onBookingSubmit(values);
      dispatch(clearExchangeOperations());
      dispatch(getBooking(booking.id));
      closeModal();
    },
    [booking.id, closeModal, dispatch, onBookingSubmit]
  );

  const {
    form: payerDetailsForm,
    handleSubmit,
    values,
    invalid,
  } = useForm<PurchaserDetailsPayload>({
    subscription: { values: true, invalid: true },
    onSubmit: isModifyFlow ? onModifySubmit : onBookingSubmit,
  });
  const purchaserInfo = usePurchaserInfo(payerDetailsForm, values);
  const showValidations = useShowValidations(payerDetailsForm, invalid);
  const onDemandTexts = bookedTrips ? getOnDemandServiceTexts(bookedTrips) : [];

  const resetAllFields = useCallback(() => {
    payerDetailsForm.restart();
    ticketFulfillmentForm.restart();
    setSelectedPassenger('');
    setSmsSelected(false);
    setEmailSelected(false);
  }, [payerDetailsForm, ticketFulfillmentForm]);

  return (
    <>
      <Stack spacing={2} divider={<Divider />}>
        <>
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h2">
              <TransTitle i18nKey="overview" />
            </Typography>
            <Button
              variant="text"
              startIcon={<Icon name="comment" />}
              onClick={onOpen}
              label={<TransButton i18nKey="addViewComments" />}
            />
          </Stack>

          {!!onDemandTexts.length && (
            <AlertCard
              title={<TransAlert i18nKey="advanceOrderRequired" />}
              message={onDemandTexts}
            />
          )}
          {isTravelPassBooking ? (
            <>
              <TravelPassOverview />
              <BookingTotalFee isOverview />
            </>
          ) : (
            <>
              <Stack spacing={1}>
                {currentTrips.map((trip) => (
                  <JourneyOverview
                    isOverview
                    trip={trip}
                    key={trip.id}
                    canDelete={currentTrips.length > 1}
                  />
                ))}
                {isModifyFlow ? <ModifyFees /> : <BookingTotalFee isOverview />}
              </Stack>
              {!isModifyFlow && IS_IMS_AT && (
                <Button
                  variant="outlined"
                  sx={{ alignSelf: 'flex-start' }}
                  startIcon={<Icon name="add" />}
                  label={<TransButton i18nKey="addJourney" />}
                  onClick={() => history.replace('/search')}
                />
              )}
            </>
          )}
          <Stack
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={2}
            sx={{ py: 3 }}
          >
            <Typography variant="h2">
              <TransField i18nKey="totalToPay" />:
            </Typography>
            <Typography variant="title">{totalToPay}</Typography>
          </Stack>
        </>
        <PayerDetailsForm
          form={payerDetailsForm}
          handleSubmit={handleSubmit}
          selectedPassenger={selectedPassenger}
          setSelectedPassenger={setSelectedPassenger}
        />
        {showPaymentMethods && (
          <>
            <TicketFulfillment
              ticketFulfillmentForm={ticketFulfillmentForm}
              isPassengerDetailsFormInvalid={invalid}
              showValidations={showValidations}
              purchaserInfo={purchaserInfo}
              isSaleFlow={true}
              isEmailSelected={isEmailSelected}
              isSmsSelected={isSmsSelected}
              setSmsSelected={setSmsSelected}
              setEmailSelected={setEmailSelected}
            />
            <PaymentMethods
              paymentMethod={paymentMethod}
              setPaymentMethod={setPaymentMethod}
            />
          </>
        )}
      </Stack>
      {isModifyFlow ? (
        <ModalControlsWrap>
          <Button
            variant="text"
            label={<TransButton i18nKey="cancel" />}
            onClick={closeModal}
          />
          <Button
            variant="contained"
            type="submit"
            form="purchaserDetails"
            loading={loading}
            label={
              <>
                <Icon name="arrow-right" sx={{ mr: 1 }} />
                {submitLabel}
              </>
            }
          />
        </ModalControlsWrap>
      ) : (
        <CartTotal>
          <>
            {IS_IMS_AT && (
              <OnHoldBookingModal
                goToNextStep={goToNextStep}
                payerDetailsForm={payerDetailsForm}
              />
            )}
            <Button
              variant="text"
              onClick={resetAllFields}
              label={<TransButton i18nKey="resetFields" />}
              disabled={isBookingExpired}
            />
            <Button
              variant="contained"
              type="submit"
              loading={loading}
              disabled={isBookingExpired || !paymentMethod}
              form="purchaserDetails"
              label={
                <>
                  <Icon name="check" sx={{ mr: 1 }} />
                  {submitLabel}
                </>
              }
            />
          </>
        </CartTotal>
      )}
      {IS_IMS_AT && paymentStatus && (
        <PayByLinkModal onPaymentSuccess={showSuccessPage} />
      )}
      <ModalWrap
        open={open}
        onClose={onClose}
        title={
          <Typography variant="subtitle">
            <TransSubtitle i18nKey="comments" />
          </Typography>
        }
        maxWidth="xl"
      >
        <Comments isSalesFlow />
      </ModalWrap>
    </>
  );
};
