import { Icon, useFormContext, useModal } from '@fleet/shared';
import { Button } from '@fleet/shared/mui';
import { alpha } from '@mui/material/styles';
import { CartTotal } from 'components/CartTotal';
import { SearchTabsContext } from 'components/SearchTabsContext';
import { PassengerSpecification, PromoCode } from 'dto/trip';
import {
  addOfferToBooking,
  getAdditionalOffers,
  getBooking,
  postBooking,
} from 'features/booking/bookingActions';
import {
  bookingLoadingSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { resetSearch } from 'features/trip/tripActions';
import {
  selectSelectedOffers,
  tripsSelector,
} from 'features/trip/tripSelector';
import { useTripSearch } from 'hooks/useTripSearch';
import { TransButton } from 'i18n/trans/button';
import { FC, useCallback, useContext, useMemo } from 'react';
import { useField } from 'react-final-form-hooks';
import { useHistory } from 'react-router-dom';
import { TripsTable } from 'routes/tickets/searchResults/TripsTable';
import { useDispatch, useSelector } from 'store/utils';
import { createOfferPayloads, getBookingDestinations } from 'utils/trip';
import { renderToString } from 'react-dom/server';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { SelectPassengersModal } from 'components/SelectPassengersModal';
import { v4 } from 'uuid';
import { IS_IMS_AT } from 'utils/flags';

interface TripSearchResultsProps {}

export const TripSearchResults: FC<TripSearchResultsProps> = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const form = useFormContext();
  const {
    input: { value: passengerSpecificationsInput },
  } = useField<Array<PassengerSpecification>>('passengerSpecifications', form);
  const {
    input: { value: promotionCodes },
  } = useField<Array<PromoCode>>('promotionCodes', form);
  const tripResults = useSelector(tripsSelector);
  const postBookingLoading = useSelector(bookingLoadingSelector);
  const booking = useSelector(currentBookingSelector);
  const { inbound: selectedInboundOffers, outbound: selectedOutboundOffers } =
    useSelector(selectSelectedOffers);
  const { offersTotal, offersSelectionIncomplete } = useTripSearch();
  const { currentTab, updateTab } = useContext(SearchTabsContext);
  const shouldShowCartTotal =
    !!offersTotal?.currency ||
    booking?.provisionalPrice ||
    currentTab?.addingJourneysBeforeCheckout;
  const { open, onOpen, onClose } = useModal();
  const isRoundTrip = !!tripResults.inboundTrips.length;
  const hasSelectedAnyOffer = isRoundTrip
    ? !!selectedInboundOffers.trips.length &&
      !!selectedOutboundOffers.trips.length
    : !!selectedOutboundOffers.trips.length;
  const shownTotal = useMemo(() => {
    return currentTab?.addingJourneysBeforeCheckout && booking?.provisionalPrice
      ? {
          ...offersTotal,
          amount: booking.provisionalPrice.amount + offersTotal.amount,
        }
      : offersTotal;
  }, [booking, currentTab?.addingJourneysBeforeCheckout, offersTotal]);
  const postBookingHandler = useCallback(async () => {
    if (
      !hasSelectedAnyOffer &&
      currentTab?.bookingId &&
      !currentTab.addingJourneysBeforeCheckout
    ) {
      await dispatch(getBooking(booking!.id)).unwrap();
      await dispatch(getAdditionalOffers()).unwrap();
      return history.push('/search/checkout');
    }

    if (!hasSelectedAnyOffer && currentTab?.addingJourneysBeforeCheckout) {
      await dispatch(getBooking(booking!.id)).unwrap();
      await dispatch(getAdditionalOffers()).unwrap();
      updateTab({
        activeStep: currentTab?.lastActiveStep || 0,
        addingAdditionalJourneyToCurrentPassenger: false,
      });
      return history.push('/search/checkout');
    }

    let passengerSpecifications = [...passengerSpecificationsInput];

    if (
      currentTab?.addingJourneysBeforeCheckout &&
      !currentTab?.addingAdditionalJourneyToCurrentPassenger &&
      currentTab.activeStep === -1
    ) {
      const existingReferences = new Set(
        booking?.passengers.map((p) => p.externalReference) || []
      );
      passengerSpecifications = passengerSpecifications.filter(
        (p) => !existingReferences.has(p.externalReference)
      );
    }

    const payload = createOfferPayloads(
      tripResults,
      selectedOutboundOffers,
      selectedInboundOffers,
      passengerSpecifications,
      promotionCodes
    );

    let tabParams;
    if (currentTab?.addingJourneysBeforeCheckout) {
      tabParams = {
        activeStep: 0,
        addingAdditionalJourneyToCurrentPassenger: false,
        name: renderToString(<TransSubtitle i18nKey="multipleJourneys" />),
      };
      await dispatch(
        addOfferToBooking({
          bookingId: currentTab.bookingId!,
          ...payload,
        })
      ).unwrap();
      await dispatch(getBooking(booking!.id)).unwrap();
    } else {
      const newBooking = await dispatch(postBooking(payload)).unwrap();
      tabParams = {
        name: getBookingDestinations(newBooking).join(' - '),
        bookingId: newBooking.id,
        activeStep: 0,
      };
    }
    await dispatch(getAdditionalOffers());
    updateTab(tabParams);
    dispatch(resetSearch());
    history.push('/search/checkout');
  }, [
    hasSelectedAnyOffer,
    selectedInboundOffers,
    selectedOutboundOffers,
    promotionCodes,
    tripResults,
    passengerSpecificationsInput,
    updateTab,
    currentTab,
    history,
    dispatch,
    booking,
  ]);

  const handleOnPassengerSelectionModalSubmit = useCallback(
    async (
      selectedOptions: Array<string>,
      isAddingToCurrentPassenger: boolean
    ) => {
      let passengerSpecifications = currentTab?.params?.passengerSpecifications;
      let addingAdditionalJourneyToCurrentPassenger =
        isAddingToCurrentPassenger;

      if (!passengerSpecifications) {
        return null;
      }

      if (booking && booking.passengers && booking.passengers.length > 0) {
        const existingPassengerSpecifications = booking.passengers
          .filter((passenger) =>
            selectedOptions.includes(passenger.externalReference)
          )
          .map((passenger) => ({
            type: 'PERSON',
            externalReference: passenger.externalReference,
          }));

        addingAdditionalJourneyToCurrentPassenger =
          existingPassengerSpecifications.some((spec) =>
            passengerSpecifications?.some(
              (p) => p.externalReference === spec.externalReference
            )
          );

        const existingReferences = new Set(
          passengerSpecifications.map((p) => p.externalReference)
        );
        const uniqueSelectedPassengers = existingPassengerSpecifications.filter(
          (p) => !existingReferences.has(p.externalReference)
        );

        passengerSpecifications = [
          ...passengerSpecifications,
          ...uniqueSelectedPassengers,
        ];
      }

      const payload = createOfferPayloads(
        tripResults,
        selectedOutboundOffers,
        selectedInboundOffers,
        passengerSpecifications,
        promotionCodes
      );

      if (currentTab?.bookingId) {
        try {
          await dispatch(
            addOfferToBooking({
              bookingId: currentTab.bookingId!,
              ...payload,
            })
          ).unwrap();
          await dispatch(getBooking(currentTab.bookingId!)).unwrap();
          updateTab({
            addingAdditionalJourneyToCurrentPassenger,
            addingJourneysBeforeCheckout: true,
            name: renderToString(<TransSubtitle i18nKey="multipleJourneys" />),
            params: {
              ...currentTab?.params,
              passengerSpecifications,
            },
          });
        } catch (e) {}
      } else {
        const booking = await dispatch(postBooking(payload)).unwrap();
        updateTab({
          bookingId: booking.id,
          addingAdditionalJourneyToCurrentPassenger,
          addingJourneysBeforeCheckout: true,
          name: getBookingDestinations(booking).join(' - '),
          params: {
            ...currentTab?.params,
            passengerSpecifications,
          },
        });
      }

      dispatch(resetSearch());
      form.reset({
        passengerSpecifications: addingAdditionalJourneyToCurrentPassenger
          ? passengerSpecifications
          : [
              {
                type: 'PERSON',
                externalReference: v4(),
              },
            ],
        departureTime: new Date().toISOString(),
        promotionCodes: [],
        corporateCodes: [],
      });
      history.replace('/search');
      onClose();
    },
    [
      selectedOutboundOffers,
      selectedInboundOffers,
      promotionCodes,
      history,
      updateTab,
      onClose,
      currentTab?.params,
      currentTab?.bookingId,
      dispatch,
      tripResults,
      booking,
      form,
    ]
  );

  return (
    <>
      <TripsTable journeys={tripResults.outboundTrips} isOutbound />
      {!!tripResults.inboundTrips.length && (
        <TripsTable journeys={tripResults.inboundTrips} />
      )}
      {shouldShowCartTotal && (
        <CartTotal offersTotal={shownTotal} isDark>
          {hasSelectedAnyOffer && IS_IMS_AT && (
            <>
              <Button
                startIcon={<Icon name="plus" />}
                sx={{ color: 'white' }}
                variant="text"
                disabled={currentTab?.lastActiveStep !== undefined}
                onClick={onOpen}
              >
                <TransButton i18nKey="addAnotherJourney" />
              </Button>
              {open && (
                <SelectPassengersModal
                  open={open}
                  onClose={onClose}
                  onSubmit={handleOnPassengerSelectionModalSubmit}
                />
              )}
            </>
          )}
          <Button
            loading={postBookingLoading}
            disabled={
              offersSelectionIncomplete &&
              !currentTab?.addingJourneysBeforeCheckout &&
              !currentTab?.bookingId
            }
            sx={{
              background: 'white!important',
              '&:hover': {
                boxShadow: (theme) =>
                  [
                    alpha(theme.palette.action.hover, 0.2),
                    theme.palette.common.white,
                  ]
                    .map((color) => `inset 0 0 0 2rem ${color}`)
                    .join(','),
              },
            }}
            variant="outlined"
            label={<TransButton i18nKey="continueCheckout" />}
            onClick={postBookingHandler}
          />
        </CartTotal>
      )}
    </>
  );
};
