import { Icon, Table, TableColumns, Tooltip } from '@fleet/shared';
import { currentDateTimeFormat, formatDate } from '@fleet/shared/utils/date';
import { Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { SeatPropertyIcon } from 'components/SeatPropertyIcon';
import { TransportBadge } from 'components/TransportBadge';
import {
  BookingAdmission,
  BookingDetailsPassenger,
  PRM_NEEDS,
  SeatProperty,
} from 'dto/booking';
import { OfferGenderProperties, TripLeg } from 'dto/trip';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { TransTableHead } from 'i18n/trans/table';
import _capitalize from 'lodash/capitalize';
import _keyBy from 'lodash/keyBy';
import _startCase from 'lodash/startCase';
import _uniq from 'lodash/uniq';
import { FC, useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';
import { useTable } from 'react-table';
import {
  getAdmissionsPlaceAllocations,
  getTransportationLabel,
  isAdmissionInactive,
} from 'utils/trip';

interface PassengerLegsProps {
  data: Array<TripLeg>;
  passenger: BookingDetailsPassenger;
  passengerAdmissions: Array<BookingAdmission>;
}

const useStyles = makeStyles(
  (theme) => ({
    table: {
      '& thead > tr': {
        background: theme.palette.common.white,
      },
      '& tbody > tr': {
        background: theme.palette.background.default,
      },
    },
    strikeThrough: {
      textDecoration: 'line-through',
      opacity: 0.75,
    },
  }),
  {
    name: 'PassengerLegs',
  }
);
export const PassengerLegs: FC<PassengerLegsProps> = ({
  passenger,
  data,
  passengerAdmissions,
}) => {
  const classes = useStyles();
  const placeAllocationsPerLeg = useMemo(
    () => _keyBy(getAdmissionsPlaceAllocations(passengerAdmissions), 'legId'),
    [passengerAdmissions]
  );
  const prmNeeds = useMemo(
    () => passenger.prmNeeds?.value ?? [],
    [passenger.prmNeeds?.value]
  );
  const admissionsByLeg = useMemo<Record<string, BookingAdmission>>(
    () =>
      passengerAdmissions.reduce(
        (map, admission) => ({
          ...map,
          ...admission.coveredLegIds.reduce(
            (acc, legId) => ({ ...acc, [legId]: admission }),
            {}
          ),
        }),
        {}
      ),
    [passengerAdmissions]
  );
  const getReservedPlaces = useCallback(
    (legId: string) => placeAllocationsPerLeg?.[legId]?.reservedPlaces ?? [],
    [placeAllocationsPerLeg]
  );
  const getPlaceIcon = useCallback(
    (legId: string) => {
      if (!placeAllocationsPerLeg[legId]) return 'seat';
      const { accomodationType, accommodationSubType } =
        placeAllocationsPerLeg[legId];
      if (accomodationType === 'SEAT') return 'seat';
      return accommodationSubType.includes('COMPARTMENT')
        ? 'compartment'
        : 'bed';
    },
    [placeAllocationsPerLeg]
  );
  const columns = useMemo<TableColumns<TripLeg>>(
    () => [
      ...(['origin', 'destination'] as const).map((place) => ({
        id: place,
        Header: <TransTableHead i18nKey={place} />,
        accessor: ({
          id,
          originStop,
          destinationStop,
          arrivalTime,
          departureTime,
        }: TripLeg) => (
          <Stack
            className={classNames({
              [classes.strikeThrough]: isAdmissionInactive(admissionsByLeg[id]),
            })}
          >
            <Typography variant="body2">
              {formatDate(
                place === 'origin' ? departureTime : arrivalTime,
                currentDateTimeFormat
              )}
            </Typography>
            <Typography variant="body2" fontWeight="bold">
              {(place === 'origin' ? originStop : destinationStop).name}
            </Typography>
          </Stack>
        ),
      })),
      {
        id: 'carrier',
        Header: <TransTableHead i18nKey="carrier" />,
        accessor: (leg) => (
          <TransportBadge
            ptMode={leg.ptMode}
            label={getTransportationLabel(
              leg,
              renderToString(<TransSubtitle i18nKey="unknownCarrier" />)
            )}
          />
        ),
      },
      {
        id: 'inventoryClass',
        accessor: ({ travelClass, id }) =>
          [
            travelClass,
            admissionsByLeg[id]?.serviceClass.name,
            placeAllocationsPerLeg[id]?.accomodationType,
          ]
            .filter(Boolean)
            .map((str) => _capitalize(_startCase(str)))
            .join(' / '),
        Header: <TransTableHead i18nKey="inventoryClass" />,
      },
      {
        id: 'compartment',
        accessor: ({ id }) => {
          const reservedPlaces = getReservedPlaces(id);
          return reservedPlaces.map(
            ({ placeDescription: compartmentNumber, placeProperties }) => {
              const genderProperty = placeProperties?.find((prop) =>
                Object.values(OfferGenderProperties).includes(
                  prop as OfferGenderProperties
                )
              );
              const gender = genderProperty
                ? renderToString(<TransLabel i18nKey={genderProperty} />)
                : '';
              return (
                <Stack direction="row" spacing={0.5}>
                  {compartmentNumber && <Icon name="compartment" />}
                  <Typography variant="body2">
                    {[compartmentNumber, gender].filter(Boolean).join('; ')}
                  </Typography>
                </Stack>
              );
            }
          );
        },
        Header: <TransTableHead i18nKey="compartmentAndGender" />,
      },
      {
        id: 'seat',
        accessor: ({ id }) => {
          const reservedPlaces = getReservedPlaces(id);
          const preparedPlaces = reservedPlaces.length
            ? reservedPlaces.filter(
                ({ placeNumber, placeDescription }) =>
                  placeNumber[0] !== placeDescription
              )
            : [
                {
                  placeNumber: renderToString(
                    <TransLabel i18nKey="freeSeating" />
                  ),
                  placeProperties: [] as SeatProperty[],
                },
              ];

          return preparedPlaces.map(({ placeNumber, placeProperties }) => (
            <Stack direction="row" spacing={0.5} key={placeNumber}>
              <Tooltip
                content={
                  prmNeeds.length ? (
                    <Stack>
                      {prmNeeds.map((prmNeed) => (
                        <Typography key={prmNeed} variant="body2">
                          <TransLabel i18nKey={prmNeed} />
                        </Typography>
                      ))}
                    </Stack>
                  ) : null
                }
              >
                <Icon
                  name={
                    prmNeeds.includes(PRM_NEEDS.WHEELCHAIR) ||
                    placeProperties.includes('WHEELCHAIR_AREA')
                      ? 'wheelchair'
                      : getPlaceIcon(id)
                  }
                />
              </Tooltip>
              <Typography variant="body2">{placeNumber}</Typography>
            </Stack>
          ));
        },
        Header: <TransTableHead i18nKey="seatBed" />,
      },
      {
        id: 'placeProperties',
        accessor: ({ id }) =>
          getReservedPlaces(id)
            .reduce<SeatProperty[]>(
              (properties, { placeProperties }) => [
                ...properties,
                ...(placeProperties ?? []),
              ],
              []
            )
            .map((property) => <SeatPropertyIcon code={property} />),
        Header: <TransTableHead i18nKey="seatProperties" />,
      },
      {
        id: 'carriage',
        accessor: ({ id }) => {
          const coachNumber = _uniq(
            getReservedPlaces(id).map(({ coachNumber }) => coachNumber)
          ).join(', ');
          return (
            <Stack direction="row" spacing={0.5}>
              {coachNumber && <Icon name="train" />}
              <Typography variant="body2">{coachNumber}</Typography>
            </Stack>
          );
        },
        Header: <TransTableHead i18nKey="carriage" />,
      },
    ],
    [
      admissionsByLeg,
      classes.strikeThrough,
      getPlaceIcon,
      getReservedPlaces,
      placeAllocationsPerLeg,
      prmNeeds,
    ]
  );

  const table = useTable({
    data: useMemo(() => data, [data]),
    columns,
  });

  return <Table table={table} classes={{ table: classes.table }} />;
};
