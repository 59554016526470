import {
  Button,
  ConfirmDeleteModal,
  currentDateTimeFormat,
  formatDate,
  FormProvider,
  Icon,
  Table,
  TableColumns,
  TableProps,
  Tooltip,
  useForm,
  useFormTable,
  useFormTableControls,
  useIndeterminateRowSelectCheckbox,
  useModal,
} from '@fleet/shared';
import { Box, Stack, Typography } from '@mui/material';
import { DaysAfterCount } from 'components/DaysAfterCount';
import { PriceWithFee } from 'components/PriceWithFee';
import { SearchTabsContext } from 'components/SearchTabsContext';
import { Tag, TagGroup } from 'components/Tag';
import {
  BookingAdmission,
  BookingDetailsPassenger,
  BookingTripWithAdmissions,
  FulfillmentStatus,
} from 'dto/booking';
import {
  getBooking,
  removePassengersFromBooking,
  resetCurrentBooking,
} from 'features/booking/bookingActions';
import {
  bookedOffersFeesMapSelector,
  bookingPartsSelector,
  currentBookingSelector,
  isTravelPassBookingSelector,
  travelPassAdmissionsSelector,
} from 'features/booking/bookingSelectors';
import { TransButton } from 'i18n/trans/button';
import { TransField } from 'i18n/trans/field';
import { TransLabel } from 'i18n/trans/label';
import { TransParagraph } from 'i18n/trans/paragraph';
import { TransTableHead } from 'i18n/trans/table';
import { TransTitle } from 'i18n/trans/title';
import _isEqual from 'lodash/isEqual';
import _isNumber from 'lodash/isNumber';
import _mapValues from 'lodash/mapValues';
import _pickBy from 'lodash/pickBy';
import _uniq from 'lodash/uniq';
import { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { useAlert } from 'react-alert';
import { useHistory } from 'react-router-dom';
import { useExpanded, useRowSelect } from 'react-table';
import { useSelector, useDispatch } from 'store/utils';
import {
  canShowAdmissionPrice,
  getAdmissionsTotalPrice,
  getTripsByLegIds,
  isAdmissionInactive,
  isCompartment,
} from 'utils/trip';

export interface PassengerData extends BookingDetailsPassenger {
  admissions: Array<BookingAdmission>;
}

interface PassengersTableProps
  extends Omit<TableProps<PassengerData>, 'table'> {
  data: Array<PassengerData>;
  hiddenColumns: Array<string>;
  selectedTrips?: Array<BookingTripWithAdmissions>;
  onRowSelectionUpdate: (selectedPassengerIds: Array<string>) => void;
  isOverview?: boolean;
}

export const PassengersTable: FC<PassengersTableProps> = ({
  data,
  hiddenColumns,
  selectedTrips,
  onRowSelectionUpdate,
  isOverview,
  ...props
}) => {
  const alert = useAlert();
  const { open: isOpen, onOpen, onClose } = useModal();
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector)!;
  const isTravelPassBooking = useSelector(isTravelPassBookingSelector);
  const travelPassAdmissions = useSelector(travelPassAdmissionsSelector);
  const selection = useSelector(bookingPartsSelector, _isEqual);
  const { bookedTrips, passengers } = booking;
  const offerFeesMap = useSelector(bookedOffersFeesMapSelector);
  const history = useHistory();
  const { resetTab } = useContext(SearchTabsContext);
  const getRowId = useCallback((row: PassengerData) => row.id, []);
  const selectedRowIds = useMemo(
    () =>
      _mapValues(
        _pickBy(selection.admission, (selection) => selection?.length),
        () => true
      ),
    [selection.admission]
  );
  const passengerStatusAccessor = useCallback(
    ({ admissions, id }: PassengerData) => {
      let statuses;
      if (isTravelPassBooking) {
        statuses = travelPassAdmissions
          .filter(({ passengerIds }) => passengerIds.includes(id))
          .map(({ fulfillments }) => fulfillments.map(({ status }) => status))
          .flat();
      } else {
        statuses = admissions.reduce<Array<FulfillmentStatus>>(
          (statuses, { status }) => _uniq([...statuses, status]),
          []
        );
      }
      if (statuses.length === 1) {
        return <TransField i18nKey={statuses[0]} />;
      } else {
        return (
          <Stack direction="row">
            <TransField i18nKey="FULFILLED" />
            {!isTravelPassBooking && (
              <Tooltip
                content={<TransParagraph i18nKey="admissionStatusHint" />}
              >
                <Icon name="info-circle" color="warning" sx={{ ml: 0.25 }} />
              </Tooltip>
            )}
          </Stack>
        );
      }
    },
    [isTravelPassBooking, travelPassAdmissions]
  );
  const journeySummaryAccessor = useCallback(
    ({ admissions }: PassengerData) => {
      if (!bookedTrips.length) return null;
      const passengerLegIds = admissions
        .filter(({ status }) => !isAdmissionInactive({ status }))
        .reduce<string[]>(
          (ids, { coveredLegIds }) => _uniq([...ids, ...coveredLegIds]),
          []
        );
      if (!passengerLegIds.length) return null;
      const passengerTrips =
        selectedTrips ?? getTripsByLegIds(booking, passengerLegIds);

      return (
        <Stack>
          {passengerTrips.map(
            ({
              alliances,
              originStop,
              destinationStop,
              departureTime,
              arrivalTime,
            }) => {
              return (
                <Stack direction="row" spacing={1} alignItems="center">
                  <Typography variant="body2" noWrap>
                    {`${formatDate(
                      departureTime,
                      currentDateTimeFormat
                    )} - ${formatDate(arrivalTime, currentDateTimeFormat)}`}
                  </Typography>
                  <DaysAfterCount
                    startDate={departureTime}
                    endDate={arrivalTime}
                  />
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Typography variant="body2" fontWeight="bold" noWrap>
                      {originStop.name}
                    </Typography>
                    <Icon name="journey-changeover" width={32} />
                    <Typography variant="body2" fontWeight="bold" noWrap>
                      {destinationStop.name}
                    </Typography>
                    {alliances.includes('RESPLUS') && (
                      <Icon name="resplus-horizontal" height={15} width={53} />
                    )}
                  </Stack>
                </Stack>
              );
            }
          )}
        </Stack>
      );
    },
    [selectedTrips, bookedTrips.length, booking]
  );

  const columns = useMemo<TableColumns<PassengerData>>(
    () => [
      {
        id: 'passengerName',
        accessor: ({ firstName, lastName }) =>
          [firstName.value, lastName.value].filter(Boolean).join(' '),
        Header: <TransTableHead i18nKey="passenger" />,
        width: 250,
      },
      {
        id: 'status',
        accessor: passengerStatusAccessor,
        Header: <TransTableHead i18nKey="status" />,
        width: 80,
      },
      {
        id: 'journeySummary',
        width: 'auto',
        accessor: journeySummaryAccessor,
        Header: <TransTableHead i18nKey="journey" />,
      },
      {
        id: 'tags',
        accessor: ({ age, prmNeeds }) => (
          <Stack direction="row" spacing={0.5}>
            {_isNumber(age) && (
              <Tag
                variant="body2"
                title={<TransLabel i18nKey="age" />}
                text={`${age}`}
              />
            )}
            {!!prmNeeds?.value?.length && (
              <TagGroup
                title={<TransLabel i18nKey="prmNeed" />}
                texts={prmNeeds.value.map((code) => (
                  <TransLabel i18nKey={code} />
                ))}
                color="action"
                subColor="hoverText"
                variant="body2"
              />
            )}
          </Stack>
        ),
      },
      {
        id: 'totalPrice',
        accessor: ({ id, admissions }) => {
          const price = isTravelPassBooking
            ? booking.confirmedPrice
            : getAdmissionsTotalPrice({
                admissions: admissions.filter((admission) =>
                  canShowAdmissionPrice(admission, id, passengers)
                ),
                offerFeesMap,
              });
          return (
            <PriceWithFee
              hideNull={admissions.every(isCompartment)}
              {...price}
            />
          );
        },
        Header: <TransTableHead i18nKey="totalPrice" />,
        width: 'auto',
      },
    ],
    [
      booking.confirmedPrice,
      isTravelPassBooking,
      journeySummaryAccessor,
      offerFeesMap,
      passengerStatusAccessor,
      passengers,
    ]
  );

  const { form } = useForm<{ rows: Array<PassengerData> }>({
    initialValues: {
      rows: data,
    },
  });

  const table = useFormTable(
    {
      data,
      columns,
      form,
      getRowId,
      initialState: {
        selectedRowIds,
        hiddenColumns,
      },
    },
    useIndeterminateRowSelectCheckbox,
    useExpanded,
    useRowSelect
  );

  const handleRowsDeleted = useCallback(async () => {
    const selectedRefs = table.selectedFlatRows.map(
      (row) => row.original.externalReference
    );
    await dispatch(
      removePassengersFromBooking({ bookingId: booking.id, refs: selectedRefs })
    ).unwrap();

    if (selectedRefs.length === table.rows.length) {
      alert.info(<TransParagraph i18nKey="bookingRemovedNoPassenger" />);
      resetTab();
      dispatch(resetCurrentBooking());
      history.replace('/search');
    } else {
      await dispatch(getBooking(booking.id)).unwrap();
    }

    onClose();
  }, [
    table.selectedFlatRows,
    onClose,
    booking.id,
    dispatch,
    table.rows.length,
    alert,
    history,
    resetTab,
  ]);

  const { removeSelectedRows } = useFormTableControls({
    table,
    form,
    removeQuery: handleRowsDeleted,
  });

  useEffect(() => {
    onRowSelectionUpdate(Object.keys(table.state.selectedRowIds));
  }, [onRowSelectionUpdate, table.state.selectedRowIds]);

  return (
    <FormProvider form={form}>
      {isOverview && (
        <Box
          sx={{ marginLeft: 'auto', marginRight: '0', width: 'fit-content' }}
        >
          <Button
            variant="text"
            color="error"
            startIcon={<Icon name="trash" />}
            onClick={onOpen}
            disabled={!Object.keys(table.state.selectedRowIds).length}
          >
            <TransButton i18nKey="delete" />
          </Button>
        </Box>
      )}
      <Table<PassengerData> {...props} table={table} />
      <ConfirmDeleteModal
        handleDelete={removeSelectedRows}
        title={<TransTitle i18nKey="deleteConfirmation" />}
        description={
          <TransParagraph
            i18nKey="deleteSelectedPassengers"
            values={{ count: table.selectedFlatRows.length }}
          />
        }
        isOpen={isOpen}
        onClose={onClose}
      />
    </FormProvider>
  );
};
