import { useMemo } from 'react';
import {
  selectSelectedOffers,
  tripsSelector,
} from 'features/trip/tripSelector';
import { useSelector } from 'store/utils';

export const useTripSearch = (): {
  offersTotal: { amount: number; currency: string };
  offersSelectionIncomplete: boolean;
} => {
  const { inboundTrips, outboundTrips } = useSelector(tripsSelector);
  const { inbound: selectedInboundOffers, outbound: selectedOutboundOffers } =
    useSelector(selectSelectedOffers);

  const isRoundTrip = inboundTrips.length > 0;

  const offersTotal = useMemo(() => {
    const allSelectedOffers = [
      ...selectedOutboundOffers.trips,
      ...(isRoundTrip ? selectedInboundOffers.trips : []),
    ];
    return allSelectedOffers.reduce(
      (total, offer) => ({
        amount: total.amount + offer.price.amount,
        currency: offer.price.currency || total.currency,
      }),
      { amount: 0, currency: '' }
    );
  }, [selectedInboundOffers, selectedOutboundOffers, isRoundTrip]);

  const offersSelectionIncomplete = useMemo(() => {
    const isRoundTrip = inboundTrips.length > 0;
    const emptyOfferSelection = [
      selectedInboundOffers,
      selectedOutboundOffers,
    ].every(({ trips }) => !trips.length);

    const outboundJourneyReference = selectedOutboundOffers.reference;
    const inboundJourneyReference = isRoundTrip
      ? selectedInboundOffers.reference
      : null;

    const selectedOutboundJourney = outboundTrips.find(
      (journey) => journey.reference === outboundJourneyReference
    );
    const selectedInboundJourney = isRoundTrip
      ? inboundTrips.find(
          (journey) => journey.reference === inboundJourneyReference
        )
      : null;

    const outboundLegIds = selectedOutboundJourney
      ? selectedOutboundJourney.trips.flatMap((trip) =>
          trip.legs.map((leg) => leg.id)
        )
      : [];
    const inboundLegIds = selectedInboundJourney
      ? selectedInboundJourney.trips.flatMap((trip) =>
          trip.legs.map((leg) => leg.id)
        )
      : [];

    const coveredOutboundLegIds = selectedOutboundOffers.trips.flatMap(
      (offer) => offer.coveredLegIds
    );
    const coveredInboundLegIds = isRoundTrip
      ? selectedInboundOffers.trips.flatMap((offer) => offer.coveredLegIds)
      : [];

    const isOutboundCovered = outboundLegIds.every((legId) =>
      coveredOutboundLegIds.includes(legId)
    );

    const isInboundCovered =
      !isRoundTrip ||
      (inboundLegIds.length > 0 &&
        inboundLegIds.every((legId) => coveredInboundLegIds.includes(legId)));
    return emptyOfferSelection || !(isOutboundCovered && isInboundCovered);
  }, [
    outboundTrips,
    inboundTrips,
    selectedOutboundOffers,
    selectedInboundOffers,
  ]);

  return {
    offersTotal,
    offersSelectionIncomplete,
  };
};
