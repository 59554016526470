import { makeStyles } from '@mui/styles';
import worldImg from 'images/world.png';
import { TripsCarousel } from 'routes/tickets/dashboard/TripsCarousel';
import { Stack } from '@mui/material';
import { TransSubtitle } from 'i18n/trans/subtitle';
import { FC, useCallback, useContext, useEffect } from 'react';
import { Loadable, useFormContext, useModal } from '@fleet/shared';
import { TripSearchParams } from 'dto/trip';
import { useDispatch, useSelector } from 'store/utils';
import {
  addTripToFavorites,
  getFavoriteTrips,
  RecentTrip,
  removeTripFromFavorites,
} from 'features/user/userActions';
import { selectFavoriteTrips } from 'features/user/userSelector';
import {
  currentBookingLoadingSelector,
  favoriteTripsLoading,
} from 'features/loading/loadingSelectors';
import { CartTotal } from 'components/CartTotal';
import { Button, Icon } from '@fleet/shared/mui';
import { alpha } from '@mui/material/styles';
import { TransButton } from 'i18n/trans/button';
import {
  bookingExpiredSelector,
  currentBookingSelector,
} from 'features/booking/bookingSelectors';
import { SearchTabsContext, SearchType } from 'components/SearchTabsContext';
import {
  deleteBooking,
  getAdditionalOffers,
  getBooking,
  resetCurrentBooking,
} from 'features/booking/bookingActions';
import { RouteChildrenProps } from 'react-router';
import { v4 } from 'uuid';
import { SelectPassengersModal } from 'components/SelectPassengersModal';
import { IS_IMS_AT } from 'utils/flags';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundImage: `url(${worldImg})`,
    backgroundSize: 'contain',
    backgroundPosition: 'center -12px',
    backgroundRepeat: 'no-repeat',
  },
  btn: {
    background: 'white!important',
    '&:first-child': {
      marginRight: '16px',
    },

    '&:hover': {
      boxShadow: () =>
        [alpha(theme.palette.action.hover, 0.2), theme.palette.common.white]
          .map((color) => `inset 0 0 0 2rem ${color}`)
          .join(','),
    },
  },
}));

interface DashboardProps extends RouteChildrenProps {}

export const Dashboard: FC<DashboardProps> = ({ history }) => {
  const dispatch = useDispatch();
  const { onOpen, open, onClose } = useModal();
  const classes = useStyles();
  const favoriteTrips = useSelector(selectFavoriteTrips);
  const loading = useSelector(favoriteTripsLoading);
  const booking = useSelector(currentBookingSelector);
  const form = useFormContext<TripSearchParams>();
  const { currentTab, resetTab, updateTab } = useContext(SearchTabsContext);
  const currentBookingLoading = useSelector(currentBookingLoadingSelector);
  const isTicketCheckout = currentTab!.type === SearchType.tickets;
  const isBookingExpired = useSelector(bookingExpiredSelector);
  const shouldShowCardTotal =
    booking &&
    (booking?.provisionalPrice?.amount ||
      currentTab?.addingJourneysBeforeCheckout);

  const applyTripParams = useCallback(
    (trip: RecentTrip) => {
      const { origin, destination } = trip;
      form.change('originStop', { code: origin.code });
      form.change('destinationStop', { code: destination.code });
    },
    [form]
  );

  const handleAddToFavorite = useCallback(
    async (payload: { origin: string; destination: string }) => {
      await dispatch(addTripToFavorites(payload)).unwrap();
      dispatch(getFavoriteTrips());
    },
    [dispatch]
  );

  const handleRemoveFromFavorite = useCallback(
    async (id: string) => {
      await dispatch(removeTripFromFavorites(id)).unwrap();
      dispatch(getFavoriteTrips());
    },
    [dispatch]
  );

  const cancelBookingHandler = useCallback(async () => {
    await dispatch(deleteBooking());
    resetTab();
    form.reset({
      passengerSpecifications: [
        {
          type: 'PERSON',
          externalReference: v4(),
        },
      ],
      ...(isTicketCheckout && { departureTime: new Date().toISOString() }),
      promotionCodes: [],
      corporateCodes: [],
    });
    dispatch(resetCurrentBooking());
    history.replace('/search');
  }, [dispatch, history, resetTab, form, isTicketCheckout]);

  const handleOnSelectPassengerModalClose = useCallback(() => {
    form.change('passengerSpecifications', [
      {
        type: 'PERSON',
        externalReference: v4(),
      },
    ]);

    updateTab({
      addingJourneysBeforeCheckout: true,
      addingAdditionalJourneyToCurrentPassenger: false,
      params: form.getState().values,
      activeStep: -1,
      lastActiveStep: currentTab?.activeStep,
    });
    onClose();
  }, [onClose, form, updateTab, currentTab?.activeStep]);

  const handleOnPassengerModalSubmit = useCallback(
    async (selectedOptions: Array<string>) => {
      form.change(
        'passengerSpecifications',
        selectedOptions.map((option) => {
          return { type: 'PERSON', externalReference: option };
        })
      );
      updateTab({
        addingJourneysBeforeCheckout: true,
        activeStep: -1,
        params: form.getState().values,
        lastActiveStep: currentTab?.activeStep,
        addingAdditionalJourneyToCurrentPassenger: true,
      });

      onClose();
    },
    [currentTab?.activeStep, form, onClose, updateTab]
  );

  useEffect(() => {
    if (!currentTab?.isCompleted && currentTab?.activeStep !== -1 && IS_IMS_AT)
      onOpen();
  }, [currentTab?.activeStep, currentTab?.isCompleted, onOpen]);

  return (
    <Loadable loading={loading || currentBookingLoading}>
      {open && (
        <SelectPassengersModal
          open={open}
          onClose={handleOnSelectPassengerModalClose}
          onSubmit={handleOnPassengerModalSubmit}
          isDashboard
        />
      )}
      <Stack className={classes.root} spacing={2} pt={3}>
        <TripsCarousel
          title={<TransSubtitle i18nKey="recentTrips" />}
          onTripClick={applyTripParams}
          addToFavorites={handleAddToFavorite}
        />
        <TripsCarousel
          title={<TransSubtitle i18nKey="favoriteTrips" />}
          trips={favoriteTrips}
          onTripClick={applyTripParams}
          removeFromFavorites={handleRemoveFromFavorite}
        />
      </Stack>
      {shouldShowCardTotal && (
        <CartTotal offersTotal={booking?.provisionalPrice} isDark>
          <Button
            variant="outlined"
            className={classes.btn}
            startIcon={<Icon name="direction-left" />}
            label={<TransButton i18nKey="cancelBooking" />}
            onClick={cancelBookingHandler}
            disabled={loading || isBookingExpired}
          />
          <Button
            loading={currentBookingLoading}
            className={classes.btn}
            variant="outlined"
            label={<TransButton i18nKey="continueCheckout" />}
            onClick={async () => {
              await dispatch(getBooking(booking!.id)).unwrap();
              await dispatch(getAdditionalOffers()).unwrap();
              updateTab({
                activeStep: currentTab?.lastActiveStep || 0,
                addingJourneysBeforeCheckout: false,
              });
              return history.push('/search/checkout');
            }}
          />
        </CartTotal>
      )}
    </Loadable>
  );
};
