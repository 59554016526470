import {
  Button,
  FormField,
  FormProvider,
  Icon,
  Loadable,
  RadioGroup,
  useForm,
} from '@fleet/shared';
import { noop } from '@fleet/shared/utils/noop';
import { ReactFleetViewer } from '@fleet/widget';
import { CompositionDirection } from '@fleet/widget/dto/composition';
import { Floor, FloorElement } from '@fleet/widget/dto/floor';
import { Vehicle } from '@fleet/widget/dto/vehicle';
import { Grid, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { CartTotal } from 'components/CartTotal';
import { Collapsible } from 'components/Collapsible';
import { LegWrapper } from 'components/LegWrapper';
import { ModifyJourneyStepsContext } from 'components/ModifyJourneyStepsProvider';
import { BookingReservation, PlaceAllocation } from 'dto/booking';
import {
  getBooking,
  updateBooking,
  UpdateBookingPayload,
} from 'features/booking/bookingActions';
import {
  currentBookingSelector,
  currentTripsSelector,
} from 'features/booking/bookingSelectors';
import { elementsSelector } from 'features/classification/classificationSelectors';
import { seatSelectionLoading } from 'features/loading/loadingSelectors';
import {
  getAvailabilitiesPlaceMap,
  TravelDirectionType,
} from 'features/trip/tripActions';
import { TransButton } from 'i18n/trans/button';
import { TransLabel } from 'i18n/trans/label';
import { TransSubtitle } from 'i18n/trans/subtitle';
import _findIndex from 'lodash/findIndex';
import _groupBy from 'lodash/groupBy';
import _isEmpty from 'lodash/isEmpty';
import _keyBy from 'lodash/keyBy';
import _uniq from 'lodash/uniq';
import {
  FC,
  ReactNode,
  SyntheticEvent,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { ModalControlsWrap } from 'routes/bookingDetails/modal/ModalControlsWrap';
import { useDispatch, useSelector } from 'store/utils';
import {
  getPassengersNames,
  getTimeString,
  getTripAdmissions,
} from 'utils/trip';
import _isEqual from 'lodash/isEqual';
import { TripLeg } from 'dto/trip';
import { TransportationTypeId } from '@fleet/widget/dto/transportation';

const useStyles = makeStyles(
  (theme) => ({
    passengerSelection: {
      padding: '0 0.5rem',
      '& .MuiFormGroup-root': { gap: '1rem' },
      '& .MuiFormControlLabel-root': {
        position: 'relative',
        height: '3.5rem',
        '& > .MuiRadio-root': {
          position: 'absolute',
          zIndex: 100,
          left: '1rem',
          '& .Icon-root': { color: '#2A3246', stroke: '#757B86' },
          '&.Mui-checked + .MuiTypography-root $passengerItem': {
            background: '#2A3246',
            '&:not($invalid), $selectedInfo p': {
              color: theme.palette.common.white,
            },
          },
        },
      },
    },
    invalid: {
      color: theme.palette.error.main,
    },
    passengerItem: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      paddingLeft: '3rem',
      borderRadius: '0.25rem',
      border: `thin solid ${theme.palette.divider}`,
      background: theme.palette.common.white,
    },
    widgetWrap: {
      '& .fleet-viewer': {
        position: 'relative',
        paddingBottom: '2rem',
        marginBottom: '1rem',
        '& .canvas': {
          padding: 0,
        },
        '& > :nth-child(2):not(.canvas)': {
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
          bottom: '-1rem',
        },
      },
    },
    selectedInfo: {},
  }),
  { name: 'SeatSelection' }
);

interface SeatSelectionProps {
  goToNextStep: () => void;
  isModifyFlow?: boolean;
  submitLabel?: ReactNode;
}

interface SelectionForm {
  [passengerId: string]: {
    [reservationId: string]: UpdateBookingPayload;
  };
}

interface PreparedReservation extends BookingReservation {
  passengerIds: string[];
}
interface PreparedItem {
  legId: string;
  tripId: string;
  reservationPerPassenger: {
    [passengerId: string]: PreparedReservation;
  };
  summary: ReactNode;
}

interface PreparedSeatElement extends FloorElement {
  coachNumber: string;
  floorIdx: number;
}

export const SeatSelection: FC<SeatSelectionProps> = ({
  submitLabel = <TransButton i18nKey="continue" />,
  isModifyFlow,
  goToNextStep,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector)!;
  const currentTrips = useSelector(currentTripsSelector, _isEqual);
  const legsMap = useMemo(
    () =>
      _keyBy(
        currentTrips.reduce<Array<TripLeg>>(
          (acc, { legs }) => [...acc, ...legs],
          []
        ),
        'id'
      ),
    [currentTrips]
  );
  const elements = useSelector(elementsSelector);
  const { closeModal } = useContext(ModifyJourneyStepsContext);
  const [selectedElements, setSelectedElements] = useState<Array<string>>([]);
  const [additionalSelection, setAdditionalSelection] = useState<Array<string>>(
    []
  );
  const makeStrKey = useCallback((key: string | number) => `str${key}`, []);
  const [widgetProps, setWidgetProps] = useState<{
    vehicles: Array<Vehicle>;
    initialVehicleIdx?: number;
    initialFloorIdx?: number;
    direction?: CompositionDirection;
    transportationTypeId?: TransportationTypeId;
  }>({ vehicles: [] });
  const [currentPassenger, setCurrentPassenger] = useState<string>();
  const loading = useSelector(seatSelectionLoading);
  const [expandedLeg, setExpandedLeg] = useState<string>();
  const passengerMap = useMemo(
    () => _keyBy(booking.passengers, 'id'),
    [booking.passengers]
  );
  const admissions = useMemo(
    () => currentTrips.map((trip) => getTripAdmissions(trip)).flat(),
    [currentTrips]
  );
  const reservationsWithPassengerIds = useMemo<
    Array<PreparedReservation>
  >(() => {
    return admissions.reduce<Array<PreparedReservation>>(
      (acc, { reservations, passengerIds }) => [
        ...acc,
        ...reservations
          .filter(({ placeAllocations }) => placeAllocations)
          .map((r) => ({ ...r, passengerIds })),
      ],
      []
    );
  }, [admissions]);
  const reservationsByLeg = useMemo(
    () =>
      _groupBy(
        reservationsWithPassengerIds,
        ({ placeAllocations }) => placeAllocations.legId
      ),
    [reservationsWithPassengerIds]
  );
  const getLegPassengers = useCallback(
    (legId: string) => {
      const legAdmissions = admissions.filter(({ coveredLegIds }) =>
        coveredLegIds.includes(legId)
      );
      return legAdmissions.reduce<Array<string>>(
        (acc, { passengerIds }) => _uniq([...acc, ...passengerIds]),
        []
      );
    },
    [admissions]
  );
  const sortPassengerIds = useCallback(
    (passengerIds: Array<string>) => {
      const passengersOrder = booking?.passengers.map(({ id }) => id);
      return passengerIds.sort(
        (a, b) => passengersOrder.indexOf(a) - passengersOrder.indexOf(b)
      );
    },
    [booking?.passengers]
  );

  const preparedData = useMemo(
    () =>
      currentTrips.reduce<Array<PreparedItem>>(
        (acc, { id: tripId, legs }) => [
          ...acc,
          ...legs.map(
            ({
              id,
              departureTime,
              arrivalTime,
              destinationStop,
              originStop,
            }) => ({
              legId: id,
              tripId,
              reservationPerPassenger: _keyBy(
                reservationsByLeg[id],
                ({ passengerIds }) => passengerIds[0]
              ),
              summary: (
                <LegWrapper small>
                  <Stack spacing={0.5}>
                    {[
                      [departureTime, originStop.name],
                      [arrivalTime, destinationStop.name],
                    ].map(([time, stop], idx) => (
                      <b key={idx}>{`${getTimeString(time)} ${stop}`}</b>
                    ))}
                  </Stack>
                </LegWrapper>
              ),
            })
          ),
        ],
        []
      ),
    [currentTrips, reservationsByLeg]
  );
  const preparedDataMap = useMemo(
    () => _keyBy(preparedData, 'legId'),
    [preparedData]
  );
  const currentReservation = useMemo<BookingReservation | undefined>(
    () =>
      expandedLeg && currentPassenger
        ? preparedDataMap[expandedLeg].reservationPerPassenger[currentPassenger]
        : undefined,
    [currentPassenger, expandedLeg, preparedDataMap]
  );
  const getPlaceType = useCallback((allocations?: PlaceAllocation) => {
    if (!allocations) return '';
    const { accomodationType, accommodationSubType } = allocations;
    if (accomodationType === 'SEAT') return accomodationType;
    return accommodationSubType.includes('COMPARTMENT') ? 'COMPARTMENT' : 'BED';
  }, []);

  const initialFormValues = useMemo(() => {
    return reservationsWithPassengerIds.reduce<SelectionForm>(
      (acc, { passengerIds, id, placeAllocations }) => {
        const [passengerId] = passengerIds;
        const { reservedPlaces, legId } = placeAllocations;
        const reservedPlace = reservedPlaces[0];
        return {
          ...acc,
          [passengerId]: {
            ...acc[passengerId],
            [makeStrKey(id)]: {
              placeSelections: [
                {
                  reservationId: id,
                  places: [
                    {
                      coachNumber: `${reservedPlace.coachNumber}`,
                      placeNumber:
                        reservedPlace.placeNumber?.[0] ??
                        reservedPlace.placeNumber,
                      passengerRef: passengerMap[passengerId].externalReference,
                    },
                  ],
                  tripLegCoverage: {
                    tripId: preparedDataMap[legId].tripId,
                    legId: legId,
                  },
                },
              ],
              selectionType: getPlaceType(placeAllocations),
            },
          },
        };
      },
      {}
    );
  }, [
    getPlaceType,
    makeStrKey,
    passengerMap,
    preparedDataMap,
    reservationsWithPassengerIds,
  ]);

  const onSubmit = useCallback(() => {
    goToNextStep();
    dispatch(getBooking(booking.id));
  }, [booking.id, dispatch, goToNextStep]);

  const { form, handleSubmit } = useForm<SelectionForm>({
    onSubmit,
    initialValues: initialFormValues,
  });

  const onPassengerChange = useCallback(
    async ({
      legId,
      passengerId,
      reservationId,
    }: {
      legId: string;
      passengerId: string;
      reservationId?: string;
    }) => {
      if (!reservationId) {
        setWidgetProps({
          vehicles: [],
          initialVehicleIdx: 0,
          initialFloorIdx: 0,
        });
        return setCurrentPassenger(passengerId);
      }
      const passengerPlaceMaps = await dispatch(
        getAvailabilitiesPlaceMap({ bookingId: booking.id, reservationId })
      ).unwrap();

      const elements = passengerPlaceMaps.reduce<
        Record<'places' | 'compartments', PreparedSeatElement[]>
      >(
        (acc, { floors, number }) => {
          const { places, compartments } = floors.reduce<
            Record<'places' | 'compartments', PreparedSeatElement[]>
          >(
            (acc, { places, compartments }, floorIdx) => ({
              places: [
                ...acc.places,
                ...places.map((place) => ({
                  ...place,
                  coachNumber: number,
                  floorIdx,
                })),
              ],
              compartments: [
                ...acc.compartments,
                ...compartments.map((place) => ({
                  ...place,
                  coachNumber: number,
                  floorIdx,
                })),
              ],
            }),
            { places: [], compartments: [] }
          );
          return {
            places: [...acc.places, ...places],
            compartments: [...acc.compartments, ...compartments],
          };
        },
        { places: [], compartments: [] }
      );

      const { reservationPerPassenger } = preparedDataMap[legId];
      const passengerIds = Object.keys(reservationPerPassenger);
      const formValues = form.getState().values;
      const selectedPlacesMap = passengerIds.reduce<
        Record<string, Array<PreparedSeatElement>>
      >((acc, passengerId) => {
        const passengerValues =
          formValues[passengerId][
            makeStrKey(reservationPerPassenger[passengerId].id)
          ];
        const passengerSelection =
          passengerValues?.placeSelections?.[0].places[0];
        const isCompartment = passengerValues?.selectionType === 'COMPARTMENT';
        const selectedCompartment =
          isCompartment &&
          elements.compartments.find(
            ({ number, coachNumber }) =>
              number === passengerSelection.placeNumber &&
              coachNumber === passengerSelection.coachNumber
          );
        const selectedPlaces = elements.places.filter(
          ({ compartmentId, number, coachNumber }) =>
            selectedCompartment
              ? selectedCompartment.id === compartmentId
              : number === passengerSelection.placeNumber &&
                coachNumber === passengerSelection.coachNumber
        );

        return {
          ...acc,
          [passengerId]: [
            ...selectedPlaces,
            ...(selectedCompartment ? [selectedCompartment] : []),
          ],
        };
      }, {});
      const { [passengerId]: currentPassengerSelection, ...rest } =
        selectedPlacesMap;

      setCurrentPassenger(passengerId);
      setSelectedElements(currentPassengerSelection.map(({ id }) => id));
      setAdditionalSelection(
        Object.values(rest)
          .flat()
          .map(({ id }) => id)
      );
      const directionsMap = {
        [TravelDirectionType.IN_DIRECTION]: CompositionDirection.FORWARD,
        [TravelDirectionType.OPPOSITE_DIRECTION]: CompositionDirection.REVERSE,
        [TravelDirectionType.CHANGING]: undefined,
        [TravelDirectionType.STARING_IN_DIRECTION]: undefined,
        [TravelDirectionType.UNSPECIFIED]: undefined,
      };
      const legTransportationCode = legsMap[legId].ptMode.code as
        | 'BUS'
        | 'TRAIN'
        | 'FERRY';
      setWidgetProps({
        transportationTypeId: TransportationTypeId[legTransportationCode],
        vehicles: passengerPlaceMaps.map(
          ({ number, direction, ...rest }) =>
            ({
              ...rest,
              orderNumber: number,
              isVehicleFlipped: direction.id === 'DIRECTION.OPPOSITE_DIRECTION',
            } as unknown as Vehicle)
        ),
        initialVehicleIdx: _findIndex(
          passengerPlaceMaps,
          ({ number }) => number === currentPassengerSelection[0].coachNumber
        ),
        initialFloorIdx: currentPassengerSelection[0]?.floorIdx ?? 0,
        direction: directionsMap?.[passengerPlaceMaps[0].direction.id],
      });
    },
    [booking.id, dispatch, form, legsMap, makeStrKey, preparedDataMap]
  );
  const getLegOpenHandler = useCallback(
    (
        legId: string,
        reservationPerPassenger: {
          [id: string]: BookingReservation;
        }
      ) =>
      async (_: SyntheticEvent, expanded: boolean) => {
        if (expanded) {
          const [passengerId] = Object.keys(reservationPerPassenger);
          const noReservation = !passengerId;
          setExpandedLeg(legId);
          await onPassengerChange({
            legId: legId,
            passengerId: noReservation ? booking.passengers[0].id : passengerId,
            reservationId: reservationPerPassenger?.[passengerId]?.id,
          });
        } else {
          setExpandedLeg(undefined);
        }
      },
    [booking.passengers, onPassengerChange]
  );
  const handleSelectPlace = useCallback(
    async (places: Array<FloorElement>, floor: Floor, vehicle: Vehicle) => {
      if (!places.length) return;
      const [place] = places;
      let prevSelectionIds;
      setSelectedElements((prev) => {
        prevSelectionIds = [...prev];
        return places.map(({ id }) => id);
      });
      if (!currentPassenger || !currentReservation) return;
      const selectedCompartment =
        place?.compartmentId && places.length > 1
          ? floor.compartments.find(({ id }) => id === place.compartmentId)
          : undefined;
      const placeNumber = selectedCompartment?.number || place?.number;
      const currentPassengerRef =
        passengerMap[currentPassenger].externalReference;
      const { id: reservationId } = currentReservation;
      const fieldPath = `${currentPassenger}.${makeStrKey(reservationId)}`;
      const prev = form.getFieldState(fieldPath)
        ?.value as unknown as UpdateBookingPayload;
      const prevSelection = prev?.placeSelections[0];
      const prevPlaceNumber =
        prevSelection && prevSelection.places[0].placeNumber;
      const prevCoachNumber =
        prevSelection && prevSelection.places[0].coachNumber;
      const shouldUpdateSelection =
        place &&
        (place.number !== prevPlaceNumber ||
          `${vehicle.orderNumber}` !== prevCoachNumber);
      if (!placeNumber) return;
      const selectionPayload = {
        placeSelections: [
          {
            reservationId,
            places: [
              {
                coachNumber: `${vehicle.orderNumber}`,
                placeNumber: placeNumber,
                passengerRef: currentPassengerRef,
              },
            ],
            tripLegCoverage: {
              tripId: preparedDataMap[expandedLeg!].tripId,
              legId: currentReservation!.placeAllocations.legId,
            },
          },
        ],
      };

      if (shouldUpdateSelection) {
        try {
          await dispatch(updateBooking(selectionPayload)).unwrap();
          // @ts-ignore
          form.change(fieldPath, { ...prev, ...selectionPayload });
        } catch (e) {
          prevSelectionIds && setSelectedElements(prevSelectionIds);
        }

        await onPassengerChange({
          legId: expandedLeg!,
          passengerId: currentPassenger,
          reservationId: currentReservation!.id,
        });
      }
    },
    [
      currentPassenger,
      currentReservation,
      dispatch,
      expandedLeg,
      form,
      makeStrKey,
      onPassengerChange,
      passengerMap,
      preparedDataMap,
    ]
  );

  const getShapeSelected = useCallback(
    ({ id }: FloorElement) =>
      [...selectedElements, ...additionalSelection].includes(id),
    [additionalSelection, selectedElements]
  );

  const getSelectedFill = useCallback(
    ({ id }: FloorElement) =>
      selectedElements.includes(id) ? '#010845' : undefined,
    [selectedElements]
  );

  useEffect(() => {
    if (!preparedData?.[0]) return;
    const { legId, reservationPerPassenger } = preparedData[0];
    getLegOpenHandler(legId, reservationPerPassenger)(
      {} as SyntheticEvent,
      true
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Loadable loading={loading}>
      <Typography variant="h1">
        <TransSubtitle i18nKey="seatSelection" />
      </Typography>
      <Grid container mt={2} columns={2}>
        <Grid item xs={1}>
          <FormProvider form={form}>
            <Stack
              component="form"
              id="seatSelectionForm"
              onSubmit={handleSubmit}
              spacing={1.5}
            >
              {preparedData.map(
                ({ legId, reservationPerPassenger, summary }) => {
                  const isFreeSeating = _isEmpty(reservationPerPassenger);
                  const legPassengerIds = sortPassengerIds(
                    isFreeSeating
                      ? getLegPassengers(legId)
                      : Object.keys(reservationPerPassenger)
                  );
                  return (
                    <Collapsible
                      key={legId}
                      collapsible
                      expanded={legId === expandedLeg}
                      onChange={getLegOpenHandler(
                        legId,
                        reservationPerPassenger
                      )}
                      title={summary}
                    >
                      <RadioGroup
                        className={classes.passengerSelection}
                        defaultValue={currentPassenger}
                        onChange={
                          isFreeSeating
                            ? noop
                            : (id) =>
                                onPassengerChange({
                                  legId,
                                  passengerId: id as string,
                                  reservationId:
                                    reservationPerPassenger[id as string].id,
                                })
                        }
                        options={legPassengerIds.map((passengerId) => ({
                          label: isFreeSeating ? (
                            <Stack
                              className={classes.passengerItem}
                              justifyContent="center"
                            >
                              <div>
                                {getPassengersNames(booking, passengerId)}
                              </div>
                              <Stack
                                direction="row"
                                className={classes.selectedInfo}
                                spacing={2}
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={0.75}
                                >
                                  <Icon name="seat" />
                                  <Typography variant="body2">
                                    <TransLabel i18nKey="freeSeating" />
                                  </Typography>
                                </Stack>
                              </Stack>
                            </Stack>
                          ) : (
                            <FormField<UpdateBookingPayload>
                              name={`${passengerId}.${makeStrKey(
                                reservationPerPassenger[passengerId].id
                              )}`}
                              render={({
                                input: {
                                  value: {
                                    placeSelections,
                                    selectionType,
                                  } = {},
                                },
                              }) => (
                                <Stack
                                  className={classes.passengerItem}
                                  justifyContent="center"
                                >
                                  <div>
                                    {getPassengersNames(
                                      booking,
                                      ...reservationPerPassenger[passengerId]
                                        .passengerIds
                                    )}
                                  </div>
                                  {placeSelections && (
                                    <Stack
                                      direction="row"
                                      className={classes.selectedInfo}
                                      spacing={2}
                                    >
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={0.75}
                                      >
                                        <Icon
                                          name={selectionType!.toLowerCase()}
                                        />
                                        <Typography variant="body2">
                                          {
                                            placeSelections[0].places[0]
                                              .placeNumber
                                          }
                                        </Typography>
                                      </Stack>
                                      <Stack
                                        direction="row"
                                        alignItems="center"
                                        spacing={0.75}
                                      >
                                        <Icon name="train" />
                                        <Typography variant="body2">
                                          {
                                            placeSelections[0].places[0]
                                              .coachNumber
                                          }
                                        </Typography>
                                      </Stack>
                                    </Stack>
                                  )}
                                </Stack>
                              )}
                            />
                          ),
                          value: passengerId,
                        }))}
                      />
                    </Collapsible>
                  );
                }
              )}
            </Stack>
          </FormProvider>
        </Grid>
        <Grid item xs={1} className={classes.widgetWrap}>
          {expandedLeg && currentPassenger && (
            <ReactFleetViewer
              allowMultipleSelect={false}
              elements={elements}
              getShapeSelected={getShapeSelected}
              getSelectedFill={getSelectedFill}
              listening={!loading}
              onSelectPlace={handleSelectPlace}
              {...widgetProps}
            />
          )}
        </Grid>
      </Grid>
      {isModifyFlow ? (
        <ModalControlsWrap>
          <Button
            variant="text"
            label={<TransButton i18nKey="cancel" />}
            onClick={closeModal}
          />
          <Button
            variant="contained"
            loading={loading}
            onClick={goToNextStep}
            label={
              <>
                <Icon name="arrow-right" sx={{ mr: 1 }} />
                {submitLabel}
              </>
            }
          />
        </ModalControlsWrap>
      ) : (
        <CartTotal>
          <>
            <Button
              type="submit"
              form="seatSelectionForm"
              variant="contained"
              loading={loading}
              label={
                <>
                  <Icon name="arrow-right" sx={{ mr: 1 }} />
                  {submitLabel}
                </>
              }
            />
          </>
        </CartTotal>
      )}
    </Loadable>
  );
};
