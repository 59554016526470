import { createTrans } from 'i18n/trans/index';
import { Trans } from 'react-i18next';

export const TransTableHead = createTrans({
  usedOn: <Trans i18nKey="table.head.usedOn" defaults="Used on" />,
  bookingNr: <Trans i18nKey="table.head.bookingNr" defaults="Booking number" />,
  action: <Trans i18nKey="table.head.action" defaults="Action" />,
  addon: <Trans i18nKey="table.head.addon" defaults="Add-on" />,
  addonPrice: (
    <Trans i18nKey="table.head.addonPrice" defaults="Add-on price incl VAT" />
  ),
  age: <Trans i18nKey="table.head.age" defaults="Age" />,
  agent: <Trans i18nKey="table.head.agent" defaults="Agent" />,
  amount: <Trans i18nKey="table.head.amount" defaults="Amount" />,
  appliedCodes: (
    <Trans i18nKey="table.head.appliedCodes" defaults="Applied codes" />
  ),
  arrivalTime: (
    <Trans i18nKey="table.head.arrivalTime" defaults="Arrival time" />
  ),
  bookingReference: (
    <Trans i18nKey="table.head.bookingReference" defaults="Booking reference" />
  ),
  carriage: <Trans i18nKey="table.head.carriage" defaults="Carriage" />,
  carrier: <Trans i18nKey="table.head.carrier" defaults="Carrier" />,
  changeable: <Trans i18nKey="table.head.changeable" defaults="Changeable" />,
  changeReason: (
    <Trans i18nKey="table.head.changeReason" defaults="Change reason" />
  ),
  city: <Trans i18nKey="table.head.city" defaults="City" />,
  commentData: (
    <Trans
      i18nKey="table.head.commentData"
      defaults="Comment and additional data"
    />
  ),
  commentLevel: (
    <Trans i18nKey="table.head.commentLevel" defaults="Comment level" />
  ),
  commentType: (
    <Trans i18nKey="table.head.commentType" defaults="Comment type" />
  ),
  compartmentAndGender: (
    <Trans
      i18nKey="table.head.compartmentAndGender"
      defaults="Compartment; Gender"
    />
  ),
  createdOn: <Trans i18nKey="table.head.createdOn" defaults="Created on" />,
  createdModifiedOn: (
    <Trans
      i18nKey="table.head.createdModifiedOn"
      defaults="Created on / Last modified"
    />
  ),
  departureTime: (
    <Trans i18nKey="table.head.departureTime" defaults="Departure time" />
  ),
  destination: (
    <Trans i18nKey="table.head.destination" defaults="Destination" />
  ),
  fee: <Trans i18nKey="table.head.fee" defaults="Fee" />,
  flexibility: (
    <Trans i18nKey="table.head.flexibility" defaults="Flexibility" />
  ),
  inventoryClass: (
    <Trans i18nKey="table.head.inventoryClass" defaults="Inventory class" />
  ),
  journey: <Trans i18nKey="table.head.journey" defaults="Journey" />,
  journeyDestination: (
    <Trans
      i18nKey="table.head.journeyDestination"
      defaults="Journey destination"
    />
  ),
  journeyOrigin: (
    <Trans i18nKey="table.head.journeyOrigin" defaults="Journey origin" />
  ),
  leg: <Trans i18nKey="table.head.leg" defaults="Leg" />,
  object: <Trans i18nKey="table.head.object" defaults="Object" />,
  origin: <Trans i18nKey="table.head.origin" defaults="Origin" />,
  passenger: <Trans i18nKey="table.head.passenger" defaults="Passenger" />,
  passengerCards: (
    <Trans i18nKey="table.head.passengerCards" defaults="Passenger cards" />
  ),
  passengers: <Trans i18nKey="table.head.passengers" defaults="Passengers" />,
  passengerType: (
    <Trans i18nKey="table.head.passengerType" defaults="Passenger type" />
  ),
  paymentMethod: (
    <Trans i18nKey="table.head.paymentMethod" defaults="Payment method" />
  ),
  paymentStatus: (
    <Trans i18nKey="table.head.paymentStatus" defaults="Payment status" />
  ),
  person: <Trans i18nKey="table.head.person" defaults="Person" />,
  price: <Trans i18nKey="table.head.price" defaults="Price" />,
  promoCode: <Trans i18nKey="table.head.promoCode" defaults="Promo code" />,
  productName: (
    <Trans i18nKey="table.head.productName" defaults="Product name" />
  ),
  qty: <Trans i18nKey="table.head.qty" defaults="Quantity" />,
  refundable: <Trans i18nKey="table.head.refundable" defaults="Refundable" />,
  refundableAmount: (
    <Trans i18nKey="table.head.refundableAmount" defaults="Refundable amount" />
  ),
  seat: <Trans i18nKey="table.head.seat" defaults="Seat" />,
  seatBed: <Trans i18nKey="table.head.seatBed" defaults="Seat/Bed" />,
  seatClass: <Trans i18nKey="table.head.seatClass" defaults="Seat class" />,
  seatProperties: (
    <Trans i18nKey="table.head.seatProperties" defaults="Seat properties" />
  ),
  status: <Trans i18nKey="table.head.status" defaults="Status" />,
  stop: <Trans i18nKey="table.head.stop" defaults="Stop" />,
  stopCode: <Trans i18nKey="table.head.stopCode" defaults="Stop code" />,
  summary: <Trans i18nKey="table.head.summary" defaults="Summary" />,
  ticket: <Trans i18nKey="table.head.ticket" defaults="Ticket" />,
  ticketNr: <Trans i18nKey="table.head.ticketNr" defaults="Ticket number" />,
  totalPrice: (
    <Trans i18nKey="table.head.totalPrice" defaults="Total price incl VAT" />
  ),
  type: <Trans i18nKey="table.head.type" defaults="Type" />,
  user: <Trans i18nKey="table.head.user" defaults="User" />,
  validFrom: <Trans i18nKey="table.head.validFrom" defaults="Valid from" />,
  validTo: <Trans i18nKey="table.head.validTo" defaults="Valid to" />,
  voucherId: <Trans i18nKey="table.head.voucherId" defaults="Voucher ID" />,
});
