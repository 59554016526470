import { FC, useCallback, useContext, useEffect, useState } from 'react';
import { useModal } from '@fleet/shared/hooks';
import { TransTitle } from 'i18n/trans/title';
import { Button } from '@fleet/shared/mui';
import { TransButton } from 'i18n/trans/button';
import {
  DateField,
  FormProvider,
  Icon,
  Loadable,
  Modal,
  useForm,
} from '@fleet/shared';
import { TransLabel } from 'i18n/trans/label';
import { useDispatch, useSelector } from 'store/utils';
import {
  getBooking,
  makeOnHoldBookingOffer,
  patchNewOnHoldBookingTime,
  putBookingOnHold,
  setOnHoldBookingTime,
} from 'features/booking/bookingActions';
import { makeStyles } from '@mui/styles';
import { putOnHoldBookingLoadingSelector } from 'features/loading/loadingSelectors';
import { OnHoldBookingOffer } from 'dto/booking';
import { Typography } from '@mui/material';
import { SearchTabsContext } from 'components/SearchTabsContext';
import {
  currentBookingSelector,
  isTravelPassBookingSelector,
} from 'features/booking/bookingSelectors';
import { FormApi } from 'final-form';
import {
  PurchaserDetailsPayload,
  updatePurchaserDetails,
} from 'features/trip/tripActions';

const useStyles = makeStyles(
  () => ({
    paper: {
      margin: 0,
      maxWidth: 'none',
      width: 600,
    },
    alertCard: {
      marginTop: '16px',
    },
  }),
  { name: 'OnHoldBookingModal' }
);

interface OnHoldBookingModalProps {
  edit?: boolean;
  goToNextStep?: () => void;
  payerDetailsForm?: FormApi<
    PurchaserDetailsPayload,
    Partial<PurchaserDetailsPayload>
  >;
}

export const OnHoldBookingModal: FC<OnHoldBookingModalProps> = ({
  edit,
  goToNextStep,
  payerDetailsForm,
}) => {
  const { updateTab } = useContext(SearchTabsContext);
  const [isInitialDateSelection, setIsInitialDateSelection] = useState(true);
  const FORM_ID = 'onHoldBookingModalForm';
  const [onHoldOfferResponse, setOnHoldOfferResponse] =
    useState<OnHoldBookingOffer>();
  const { onHoldOffer } = onHoldOfferResponse || {};
  const classes = useStyles();
  const booking = useSelector(currentBookingSelector);
  const loading = useSelector(putOnHoldBookingLoadingSelector);
  const { open, onOpen, onClose } = useModal();
  const dispatch = useDispatch();
  const isTravelPassBooking = useSelector(isTravelPassBookingSelector);

  const makeOnHoldOffer = useCallback(async () => {
    await dispatch(makeOnHoldBookingOffer())
      .unwrap()
      .then((offer) => {
        setOnHoldOfferResponse(offer);
      });
  }, [dispatch]);

  const confirmOnHoldBooking = useCallback(
    async ({ onHoldBookingDate }) => {
      if (!onHoldBookingDate) {
        return;
      }
      await dispatch(
        patchNewOnHoldBookingTime({
          validUntil: onHoldBookingDate,
          offerId: onHoldOffer?.id,
        })
      ).unwrap();

      if (edit) {
        onClose();
        await dispatch(getBooking(booking!.id)).unwrap();
      } else {
        if (payerDetailsForm) {
          // eslint-disable-next-line
          const { bookingId, ...values } = payerDetailsForm?.getState().values;

          await dispatch(
            updatePurchaserDetails({
              bookingId: booking!.id,
              ...values,
            })
          ).unwrap();
          await dispatch(putBookingOnHold(onHoldOffer!.id))
            .unwrap()
            .then(() => {
              updateTab({ isCompleted: true });
              dispatch(setOnHoldBookingTime(onHoldBookingDate));
              goToNextStep && goToNextStep();
            });
        }
      }
    },
    [
      dispatch,
      onHoldOffer,
      goToNextStep,
      edit,
      updateTab,
      booking,
      onClose,
      payerDetailsForm,
    ]
  );

  const { form, handleSubmit } = useForm<{ onHoldBookingDate: string }>({
    onSubmit: confirmOnHoldBooking,
    subscription: { values: true },
  });

  const handleDateChange = useCallback(
    (newDate: Date) => {
      if (isInitialDateSelection) {
        newDate.setHours(23, 59, 59);
        setIsInitialDateSelection(false);
      }
      form.change('onHoldBookingDate', String(newDate.toISOString()));
    },
    [form, isInitialDateSelection]
  );

  useEffect(() => {
    if (
      onHoldOfferResponse?.onHoldOffer?.increasedTTL ||
      booking?.confirmableUntil
    ) {
      form.change(
        'onHoldBookingDate',
        onHoldOfferResponse?.onHoldOffer.increasedTTL ??
          booking?.confirmableUntil
      );
    }
  }, [onHoldOfferResponse, form, booking?.confirmableUntil]);

  if (isTravelPassBooking) {
    return null;
  }

  return (
    <>
      {edit ? (
        <Button variant="text" onClick={onOpen} sx={{ p: 0 }}>
          <Typography variant="body2" sx={{ ml: '8px', pl: 0, mr: 'auto' }}>
            <TransButton i18nKey="edit" />
          </Typography>
        </Button>
      ) : (
        <Button
          startIcon={<Icon name="save" />}
          variant="outlined"
          disabled={
            payerDetailsForm &&
            !Object.entries(payerDetailsForm?.getState().values).length
          }
          onClick={() => {
            onOpen();
            makeOnHoldOffer();
          }}
        >
          <TransButton i18nKey="putBookingOnHold" />
        </Button>
      )}
      <Modal
        classes={{
          paper: classes.paper,
        }}
        title={<TransTitle i18nKey="putBookingOnHold" />}
        open={open}
        disableBackdropClick
        onClose={onClose}
        showCloseControl={true}
        actionButton={
          <>
            <Button
              form={FORM_ID}
              type="submit"
              variant="contained"
              onClick={confirmOnHoldBooking}
              disabled={edit ? false : !onHoldOffer?.id}
            >
              <TransButton i18nKey="putBookingOnHold" />
            </Button>
          </>
        }
      >
        <Loadable loading={loading}>
          <FormProvider form={form}>
            <form onSubmit={handleSubmit} id={FORM_ID}>
              <DateField
                minDate={new Date()}
                showTimeInput
                name="onHoldBookingDate"
                onChange={(date) => handleDateChange(date as Date)}
                label={<TransLabel i18nKey="onHoldUntil" />}
              />
            </form>
          </FormProvider>
        </Loadable>
      </Modal>
    </>
  );
};
