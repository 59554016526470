import {
  createContext,
  FC,
  MouseEvent,
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { noop } from '@fleet/shared/utils/noop';
import { alpha, Box, Tab, Tabs, Typography } from '@mui/material';
import { Icon } from '@fleet/shared/mui';
import { Loadable } from '@fleet/shared';
import { useDispatch, useSelector } from 'store/utils';
import { OverflowEllipsis } from 'components/OverflowEllipsis';
import { TransButton } from 'i18n/trans/button';
import { PassengerCardState, TripSearchParams } from 'dto/trip';
import { makeStyles } from '@mui/styles';
import {
  ACTIVE_TAB_LS_KEY,
  TABS_LS_KEY,
  useLocalStorage,
} from 'hooks/useLocalStorage';
import { searchLoadingSelector } from 'features/loading/loadingSelectors';
import { v4 } from 'uuid';
import { resetCurrentBooking } from 'features/booking/bookingActions';
import { currentBookingSelector } from 'features/booking/bookingSelectors';

export enum SearchType {
  tickets = 'tickets',
  travelPass = 'travelPass',
}

export type SearchTab = {
  activeStep: number;
  name?: string;
  summary?: {
    title: string;
    description: string;
  };
  params?: Partial<TripSearchParams>;
  cardState?: PassengerCardState;
  type: SearchType;
  bookingId?: string;
  isCompleted?: boolean;
  addingAdditionalJourneyToCurrentPassenger?: boolean;
  addingJourneysBeforeCheckout?: boolean;
  lastActiveStep?: number;
  redirectToOverview?: boolean;
};

export interface SearchTabsContext
  extends Omit<SearchTabsProviderProps, 'onTabChange'> {
  activeTabIdx: number;
  currentTab?: SearchTab;
  updateTab: (payload: Partial<SearchTab>, idx?: number) => void;
  resetTab: (idx?: number) => void;
  closeTab: (idx: number) => void;
}

interface SearchTabsProviderProps {
  initialTabs?: Array<SearchTab>;
  onTabChange: (nextTab: SearchTab) => void;
}

const defaultValue: SearchTabsContext = {
  activeTabIdx: 0,
  updateTab: noop,
  resetTab: noop,
  closeTab: noop,
};

const useStyles = makeStyles(
  (theme) => ({
    tabsIndicator: {
      display: 'none',
    },
    tab: {
      height: 40,
      textTransform: 'none',
      borderRadius: '.25rem .25rem 0 0',
      flexDirection: 'row',
      '&:hover': {
        color: theme.palette.primary.main,
      },
      '&.Mui-selected': {
        boxShadow: `0 2px 8px 0 ${alpha(theme.palette.common.black, 0.1)}`,
        backgroundColor: theme.palette.common.white,
      },
    },
  }),
  {
    name: 'SearchTabsProvider',
  }
);

export const SearchTabsContext = createContext<SearchTabsContext>(defaultValue);

export const SearchTabsProvider: FC<SearchTabsProviderProps> = ({
  children,
  onTabChange,
}) => {
  const dispatch = useDispatch();
  const booking = useSelector(currentBookingSelector);
  const classes = useStyles();
  const emptyTab = useMemo<SearchTab>(
    () => ({ params: {}, type: SearchType.tickets, activeStep: -1 }),
    []
  );
  const { initialValue: lsTabs, setData: setLsTabs } = useLocalStorage<
    Array<SearchTab>
  >({
    key: TABS_LS_KEY,
    getDefaultValue: (lsTabs) => (lsTabs?.length ? lsTabs : [emptyTab]),
    isSessionStorage: true,
  });
  const { initialValue: lsActiveTab, setData: setLsActiveTab } =
    useLocalStorage<number>({
      key: ACTIVE_TAB_LS_KEY,
      getDefaultValue: (idx) => {
        if (!idx) return 0;
        return idx >= lsTabs.length ? lsTabs.length - 1 : idx;
      },
      isSessionStorage: true,
    });
  const [activeTabIdx, setActiveTab] = useState<number>(+lsActiveTab);
  const [tabs, setTabs] = useState<Array<SearchTab>>(lsTabs);
  const activeTab = tabs[activeTabIdx];
  const clearFinishedTabs = useCallback(() => {
    setTabs((tabs) => {
      const clearedTabs = tabs.filter(({ isCompleted }) => !isCompleted);
      const nextTabs = clearedTabs.length ? clearedTabs : [emptyTab];
      setLsTabs(nextTabs);
      setActiveTab((prev) =>
        prev >= nextTabs.length ? nextTabs.length - 1 : prev
      );

      return nextTabs;
    });
  }, [emptyTab, setLsTabs]);

  useEffect(() => {
    setLsActiveTab(activeTabIdx);
    setLsTabs(tabs);

    return () => {
      if (tabs[activeTabIdx].isCompleted) {
        setLsActiveTab(0);
        setLsTabs(tabs.filter((_, idx) => idx !== activeTabIdx));
      }
    };
  }, [tabs, activeTabIdx, setLsActiveTab, setLsTabs]);

  useEffect(() => {
    onTabChange(activeTab);
  }, [onTabChange, activeTab]);

  useEffect(() => {
    return () => {
      clearFinishedTabs();
    };
  }, [clearFinishedTabs]);

  const loading = useSelector(searchLoadingSelector);

  const updateTab = useCallback(
    (updates: Partial<SearchTab>, tabIdx: number = activeTabIdx) => {
      setTabs((tabs) =>
        tabs.map((tab, idx) => (idx === tabIdx ? { ...tab, ...updates } : tab))
      );
    },
    [activeTabIdx]
  );

  const resetTab = useCallback(
    (tabIdx: number = activeTabIdx) => {
      setTabs((tabs) =>
        tabs.map((tab, idx) =>
          idx === tabIdx
            ? {
                addingJourneysBeforeCheckout: false,
                type: SearchType.tickets,
                params: {
                  passengerSpecifications: [
                    {
                      type: 'PERSON',
                      externalReference: v4(),
                      cards: [],
                    },
                  ],
                },
                activeStep: -1,
              }
            : tab
        )
      );
    },
    [activeTabIdx]
  );

  const addTab = useCallback(() => {
    if (tabs[activeTabIdx]?.isCompleted) {
      resetTab();
    }
    setTabs((tabs) => [...tabs, emptyTab]);
    setActiveTab(tabs.length);
    dispatch(resetCurrentBooking());
  }, [tabs, emptyTab, dispatch, activeTabIdx, resetTab]);

  const closeTab = useCallback(
    (idx: number) => {
      const nextTabs =
        tabs.length === 1
          ? [emptyTab]
          : tabs.filter((tab, tabIdx) => idx !== tabIdx);

      if (idx <= activeTabIdx) {
        const nextActiveIdx = activeTabIdx - 1;
        setActiveTab(nextActiveIdx < 0 ? 0 : nextActiveIdx);
      }

      dispatch(resetCurrentBooking());
      setTabs(nextTabs);
    },
    [activeTabIdx, emptyTab, tabs, dispatch]
  );

  const getCloseTabHandler = useCallback(
    (idx: number) => (e: MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      closeTab(idx);
    },
    [closeTab]
  );

  const currentTab = useMemo(() => tabs[activeTabIdx], [tabs, activeTabIdx]);

  const changeTab = useCallback(
    (event: SyntheticEvent, nextIdx: number) => {
      if (!tabs[nextIdx]) return;

      if (!tabs[nextIdx].bookingId && booking) {
        dispatch(resetCurrentBooking());
      }

      setActiveTab(nextIdx);
    },
    [tabs, booking, dispatch]
  );

  const value: SearchTabsContext = useMemo(
    () => ({
      activeTabIdx,
      updateTab,
      resetTab,
      currentTab,
      closeTab,
    }),
    [activeTabIdx, closeTab, currentTab, resetTab, updateTab]
  );

  return (
    <Loadable loading={loading}>
      <Tabs
        value={activeTabIdx}
        onChange={changeTab}
        classes={{
          indicator: classes.tabsIndicator,
        }}
      >
        {tabs.map(({ name }, idx) => (
          <Tab
            key={idx}
            value={idx}
            classes={{ root: classes.tab }}
            icon={
              <Box aria-label="close" onClick={getCloseTabHandler(idx)}>
                <Icon name="close" size={14} />
              </Box>
            }
            iconPosition="end"
            label={
              <OverflowEllipsis
                variant="subtitle"
                content={name || <TransButton i18nKey="newBooking" />}
                sx={{
                  maxWidth: 150,
                }}
              />
            }
          />
        ))}
        <Tab
          icon={<Icon name="add" />}
          iconPosition="start"
          label={
            <Typography variant="subtitle">
              <TransButton i18nKey="newBooking" />
            </Typography>
          }
          onClick={addTab}
        />
      </Tabs>
      <SearchTabsContext.Provider value={value}>
        {children}
      </SearchTabsContext.Provider>
    </Loadable>
  );
};
